import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Main from "./components/Reseller/main.js";

//import ClientMain from "./components/Client/main.js";

import AdminMain from "./components/Admin/admin";

import Test from "./Test.js";

import Error404 from "./components/Errors/404";
import Forgot from "./Pages/Reseller/Forgot/index";
import PasswordSetting from "./Pages/Reseller/PasswordSetting/index";
import SignUpVerification from "./Pages/Reseller/Verification/index";
import Sso from "./Pages/Reseller/Sso/index";
import Login from "./Pages/Reseller/Login/index";
import Resolver from "./Pages/Reseller/Resolver/index";

import Register from "./Pages/Reseller/Register/index";
import Addservice from "./Pages/Reseller/Services/addservice";

import AgreementsVerification from "./Pages/Reseller/AgreementsVerification/index";

import AdminLogin from "./Pages/Admin/Login/admin_login";

import ClientLogin from "./Pages/Client/Login/index";
import ClientRegister from "./Pages/Client/Register/index";
import ClientSignUpVerification from "./Pages/Client/Verification/index";
import ClientAgreementVerification from "./Pages/Client/AgreementVerification/index";
import ClientForgot from "./Pages/Client/Forgot/index";
import ClientPasswordSetting from "./Pages/Client/PasswordSetting/index";
import SsoAuthentication from "./Pages/Client/SsoAuthentication/index";
import QuotationVerification from "./Pages/Client/QuotationVerification/index";
import FormVerification from "./Pages/Client/FormVerification/index";
import FormVerificationCp from "./Pages/Reseller/FormVerification/index";
import ClientHelp from "./Pages/Client/Help/index";
import ClientMain from "./components/Client/main.js";
import OAuth from "./Pages/Client/OAuth/index.js";

import PrivacyPolicy from "./Pages/Client/Pages/privacyPolicy.js";

import SubClientRegister from "./Pages/Client/Register/subClientRegister.js";

function App() {
  console.log("version ", React.version);

  return (
    <div>
      <Router>
        <Switch>
          {/* COSTOMER RESELLER ROUTES */}

          <Route
            exact
            path="/test"
            component={(props) => <Test {...props} />}
          />
          <Route
            exact
            path="/login/:reseller_url?"
            component={(props) => <Login {...props} />}
          />

          <Route
            exact
            path="/dashboard"
            component={(props) => <Main {...props} view="dashboard" />}
          />
          <Route
            exact
            path="/services"
            component={(props) => <Main {...props} view="services" />}
          />
          <Route
            exact
            path="/services/Addservice"
            component={(props) => <Main {...props} view="Addservice" />}
          />
          {/* Quotation Routes */}
          <Route
            exact
            path="/partner_quotations"
            component={(props) => <Main {...props} view="partner_quotations" />}
          />
          <Route
            exact
            path="/partner_rfqs"
            component={(props) => <Main {...props} view="partner_rfqs" />}
          />
          <Route
            exact
            path="/partner_orders"
            component={(props) => <Main {...props} view="partner_orders" />}
          />
          <Route
            exact
            path="/ViewSalesOrder/:orderno"
            component={(props) => <Main {...props} view="ViewSalesOrder" />}
          />
          <Route
            exact
            path="/EditSalesOrder/:client_accountno/:orderno"
            component={(props) => <Main {...props} view="EditSalesOrder" />}
          />
          <Route
            exact
            path="/EditSalesOrder/:client_accountno/:orderno/:quote_accountno?"
            component={(props) => <Main {...props} view="EditSalesOrder" />}
          />
          <Route
            exact
            path="/EditQuotations/:client_accountno/:quote_accountno"
            component={(props) => <Main {...props} view="EditQuotations" />}
          />
          <Route
            exact
            path="/EditQuotations/:client_accountno/:quote_accountno/:rfq_accountno?"
            component={(props) => <Main {...props} view="EditQuotations" />}
          />
          {/* Quotation Routes */}
          <Route
            exact
            path="/documents"
            component={(props) => <Main {...props} view="documents" />}
          />
          <Route
            exact
            path="/cp_forms"
            component={(props) => <Main {...props} view="cp_forms" />}
          />
          <Route
            exact
            path="/agent_form_submit/:form_accountno/:form_token"
            component={(props) => <Main {...props} view="agent_form_submit" />}
          />
          <Route
            exact
            path="/agent_form_submissions/:form_accountno/:form_token"
            component={(props) => (
              <Main {...props} view="agent_form_submissions" />
            )}
          />
          <Route
            exact
            path="/agent_single_submissions/:form_token"
            component={(props) => (
              <Main {...props} view="agent_single_submissions" />
            )}
          />
          <Route
            exact
            path="/cms"
            component={(props) => <Main {...props} view="cms" />}
          />
          <Route
            exact
            path="/cms/Pageslist"
            component={(props) => <Main {...props} view="Pageslist" />}
          />
          <Route
            exact
            path="/cms/Addpages"
            component={(props) => <Main {...props} view="Addpages" />}
          />
          <Route
            exact
            path="/cms/Editpage/:id"
            component={(props) => <Main {...props} view="Editpage" />}
          />
          <Route
            exact
            path="/agreements/Pdfdownload/:id"
            component={(props) => <Main {...props} view="Pdfdownload" />}
          />
          <Route
            exact
            path="/agreements/UnsignedAgreements"
            component={(props) => <Main {...props} view="UnsignedAgreements" />}
          />
          <Route
            exact
            path="/cms/Menuslist"
            component={(props) => <Main {...props} view="Menuslist" />}
          />
          <Route
            exact
            path="/cms/Addmenus"
            component={(props) => <Main {...props} view="Addmenus" />}
          />
          <Route
            exact
            path="/cms/Editmenu/:id"
            component={(props) => <Main {...props} view="Editmenu" />}
          />
          <Route
            exact
            path="/cms/Headerlist"
            component={(props) => <Main {...props} view="Headerlist" />}
          />
          <Route
            exact
            path="/cms/Addheader"
            component={(props) => <Main {...props} view="Addheader" />}
          />
          <Route
            exact
            path="/cms/Websettings"
            component={(props) => <Main {...props} view="Websettings" />}
          />
          <Route
            exact
            path="/themes/Editor"
            component={(props) => <Main {...props} view="Editor" />}
          />
          <Route
            exact
            path="/themes"
            component={(props) => <Main {...props} view="themes" />}
          />
          <Route
            exact
            path="/domains"
            component={(props) => <Main {...props} view="domains" />}
          />
          <Route
            exact
            path="/domains/Adddomains"
            component={(props) => <Main {...props} view="Adddomains" />}
          />
          <Route
            exact
            path="/clients"
            component={(props) => <Main {...props} view="clients" />}
          />
          <Route
            exact
            path="/clients/Addclient"
            component={(props) => <Main {...props} view="Addclient" />}
          />
          <Route
            exact
            path="/clients/clients_queue"
            component={(props) => <Main {...props} view="clients_queue" />}
          />
          <Route
            exact
            path="/analytics"
            component={(props) => <Main {...props} view="analytics" />}
          />
          <Route
            exact
            path="/reports/:api_url/"
            component={(props) => <Main {...props} view="reports" />}
          />
          <Route
            exact
            path="/pages/:pageUrl"
            component={(props) => <PrivacyPolicy {...props} />}
          />
          <Route
            exact
            path="/agreements"
            component={(props) => <Main {...props} view="agreements" />}
          />
          <Route
            exact
            path="/sharelinks"
            component={(props) => <Main {...props} view="sharelinks" />}
          />
          <Route
            exact
            path="/sharelinks/sharelink_settings"
            component={(props) => <Main {...props} view="sharelink_settings" />}
          />

          <Route
            exact
            path="/sharelinks/add_sharelink"
            component={(props) => <Main {...props} view="add_sharelink" />}
          />

          <Route
            exact
            path="/sharelinks/analytics"
            component={(props) => (
              <Main {...props} view="sharelink_analytics" />
            )}
          />

          <Route
            exact
            path="/:reseller_url?/agreements_verification/:verification_token"
            component={(props) => <AgreementsVerification {...props} />}
          />

          <Route
            exact
            path="/PaymentsHistory"
            component={(props) => <Main {...props} view="PaymentsHistory" />}
          />

          <Route
            exact
            path="/Payouts"
            component={(props) => <Main {...props} view="Payouts" />}
          />

          <Route
            exact
            path="/CommissionReport"
            component={(props) => <Main {...props} view="CommissionReport" />}
          />

          <Route
            exact
            path="/CommissionSettings"
            component={(props) => <Main {...props} view="CommissionSettings" />}
          />

          <Route
            exact
            path="/agents"
            component={(props) => <Main {...props} view="agents" />}
          />

          <Route
            exact
            path="/agents/Addagent"
            component={(props) => <Main {...props} view="Addagent" />}
          />
          <Route
            exact
            path="/upgrades"
            component={(props) => <Main {...props} view="upgrades" />}
          />
          <Route
            exact
            path="/backoffice"
            component={(props) => <Main {...props} view="back office" />}
          />
          <Route
            exact
            path="/msa_signing"
            component={(props) => <Main {...props} view="msa_signing" />}
          />
          <Route
            exact
            path="/verification/:token_code/"
            component={SignUpVerification}
          />

          <Route exact path="/sso/:reseller_url/:token_code/" component={Sso} />

          <Route
            exact
            path="/:reseller_url?/client/OAuth/:token"
            component={OAuth}
          />

          <Route exact path="/forgot/" component={Forgot} />
          <Route
            exact
            path="/password_setting/:token_code/"
            component={PasswordSetting}
          />

          {/* END CUSTOMER RESELLER ROUTES */}

          {/* START CLIENT ROUTES */}

          <Route exact path="/client/register" component={Error404} />
          <Route
            exact
            path="/client/register/:signup_hash"
            component={(props) => <ClientRegister {...props} />}
          />
          <Route
            exact
            path="/client/register/:signup_hash/:serviceid"
            component={(props) => <ClientRegister {...props} />}
          />
          <Route
            exact
            path="/client/register/:signup_hash/:serviceid/:servicetype?"
            component={(props) => <ClientRegister {...props} />}
          />
          <Route
            exact
            path="/client/register/:signup_hash/:serviceid/:servicetype?/:sharelink_hash?"
            component={(props) => <ClientRegister {...props} />}
          />
          <Route
            exact
            path="/client/sub_register/:signup_hash"
            component={(props) => <SubClientRegister {...props} />}
          />

          <Route
            exact
            path="/client/sub_register/:signup_hash/:serviceid"
            component={(props) => <SubClientRegister {...props} />}
          />

          <Route
            exact
            path="/client/sub_register/:signup_hash/:serviceid/:servicetype?"
            component={(props) => <SubClientRegister {...props} />}
          />
          <Route
            exact
            path="/client/verification/:verification_token"
            component={(props) => <ClientSignUpVerification {...props} />}
          />

          <Route
            exact
            path="/client/agreement_verification/:verification_token"
            component={(props) => <ClientAgreementVerification {...props} />}
          />
          <Route
            exact
            path="/:reseller_url?/client/agreement_verification/:verification_token"
            component={(props) => <ClientAgreementVerification {...props} />}
          />

          <Route
            exact
            path="/:reseller_url?/client/dashboard"
            component={(props) => <ClientMain {...props} view="dashboard" />}
          />

          <Route
            exact
            path="/:reseller_url?/client/services"
            component={(props) => <ClientMain {...props} view="services" />}
          />

          <Route
            exact
            path="/:reseller_url?/client/help"
            component={(props) => <ClientMain {...props} view="help" />}
          />

          <Route
            exact
            path="/:reseller_url?/client/CreditApp"
            component={(props) => <ClientMain {...props} view="CreditApp" />}
          />

          <Route
            exact
            path="/:reseller_url?/client/AddCreditApp/:credit_id/"
            component={(props) => <ClientMain {...props} view="AddCreditApp" />}
          />

          <Route
            exact
            path="/:reseller_url?/client/AccountActivity"
            component={(props) => (
              <ClientMain {...props} view="AccountActivity" />
            )}
          />

          <Route
            exact
            path="/:reseller_url?/client/KycForm"
            component={(props) => <ClientMain {...props} view="KycForm" />}
          />

          <Route
            exact
            path="/:reseller_url?/client/Rfqs"
            component={(props) => <ClientMain {...props} view="Rfqs" />}
          />

          <Route
            exact
            path="/:reseller_url?/client/AddRfq/:rfq_id?/"
            component={(props) => <ClientMain {...props} view="AddRfq" />}
          />

          <Route
            exact
            path="/:reseller_url?/client/EditRfq/:rfq_id?/"
            component={(props) => <ClientMain {...props} view="EditRfq" />}
          />

          <Route
            exact
            path="/:reseller_url?/client/Quotations"
            component={(props) => <ClientMain {...props} view="Quotations" />}
          />

          <Route
            exact
            path="/:reseller_url?/client/ViewQuotation/:quote_accountno"
            component={(props) => (
              <ClientMain {...props} view="ViewQuotation" />
            )}
          />

          <Route
            exact
            path="/:reseller_url?/client/SalesOrders"
            component={(props) => <ClientMain {...props} view="SalesOrders" />}
          />

          <Route
            exact
            path="/:reseller_url?/client/ViewSalesOrder/:orderno"
            component={(props) => (
              <ClientMain {...props} view="ViewSalesOrder" />
            )}
          />

          <Route
            exact
            path="/:reseller_url?/client/EditSalesOrder/:orderno?/:service_id?/"
            component={(props) => (
              <ClientMain {...props} view="EditSalesOrder" />
            )}
          />

          <Route
            exact
            path="/:reseller_url?/client/BillingInformation"
            component={(props) => (
              <ClientMain {...props} view="BillingInformation" />
            )}
          />

          <Route
            exact
            path="/:reseller_url?/client/PaymentHistory"
            component={(props) => (
              <ClientMain {...props} view="PaymentHistory" />
            )}
          />

          <Route
            exact
            path="/:reseller_url?/client/Pricing"
            component={(props) => <ClientMain {...props} view="Pricing" />}
          />

          <Route
            exact
            path="/:reseller_url?/client/Invoices"
            component={(props) => <ClientMain {...props} view="Invoices" />}
          />

          <Route
            exact
            path="/:reseller_url?/client/Cdr"
            component={(props) => <ClientMain {...props} view="Cdr" />}
          />

          <Route
            exact
            path="/:reseller_url?/client/SmsRecords"
            component={(props) => <ClientMain {...props} view="SmsRecords" />}
          />
          <Route
            exact
            path="/:reseller_url?/client/MouStats"
            component={(props) => <ClientMain {...props} view="MouStats" />}
          />
          <Route
            exact
            path="/:reseller_url?/client/BalanceUsage"
            component={(props) => <ClientMain {...props} view="BalanceUsage" />}
          />

          <Route
            exact
            path="/:reseller_url?/client/Loaders"
            component={(props) => <ClientMain {...props} view="Loaders" />}
          />

          <Route
            exact
            path="/:reseller_url?/client/sso_authentication/:client_id/:token_code"
            component={SsoAuthentication}
          />

          <Route
            exact
            path="/:reseller_url?/client/quotation_payment/:client_accountno/:verification_token"
            component={QuotationVerification}
          />

          <Route
            exact
            path="/:reseller_url?/client/client_forms/:form_token"
            component={FormVerification}
          />

          <Route
            exact
            path="/partner_forms/:form_token"
            component={FormVerificationCp}
          />

          <Route
            exact
            path="/:reseller_url?/client/SubClients"
            component={(props) => <ClientMain {...props} view="SubClients" />}
          />

          <Route
            exact
            path="/:reseller_url?/client/SubClients/AddSubClient"
            component={(props) => <ClientMain {...props} view="AddSubClient" />}
          />

          <Route
            exact
            path="/:reseller_url?/client/services/Addservice"
            component={(props) => <ClientMain {...props} view="Addservice" />}
          />
          <Route
            exact
            path="/:reseller_url?/client/forms"
            component={(props) => <ClientMain {...props} view="forms" />}
          />
          <Route
            exact
            path="/:reseller_url?/client/client_form_submit/:form_accountno/:form_token?/:service_id?"
            component={(props) => (
              <ClientMain {...props} view="client_form_submit" />
            )}
          />
          <Route
            exact
            path="/:reseller_url?/client/client_form_submissions/:form_accountno/:form_token?"
            component={(props) => (
              <ClientMain {...props} view="client_form_submissions" />
            )}
          />
          <Route
            exact
            path="/:reseller_url?/client/client_form_submission_single/:form_token?"
            component={(props) => (
              <ClientMain {...props} view="client_form_submission_single" />
            )}
          />

          <Route
            exact
            path="/:reseller_url?/client/agreements"
            component={(props) => <ClientMain {...props} view="agreements" />}
          />

          <Route
            exact
            path="/:reseller_url?/client/agreements/UnsignedAgreements/"
            component={(props) => (
              <ClientMain {...props} view="UnsignedAgreements" />
            )}
          />

          <Route
            exact
            path="/:reseller_url?/client/agreements/UnsignedAgreement/:agreement_no?"
            component={(props) => (
              <ClientMain {...props} view="UnsignedAgreement" />
            )}
          />

          <Route
            exact
            path="/:reseller_url?/client/agreements/pdfdownload/:id"
            component={(props) => <ClientMain {...props} view="Pdfdownload" />}
          />

          <Route
            exact
            path="/:reseller_url?/client/forgot"
            component={ClientForgot}
          />

          <Route
            exact
            path="/:reseller_url?/client/password_setting/:token_code/"
            component={ClientPasswordSetting}
          />

          <Route
            exact
            path="/:reseller_url?/client/login"
            component={(props) => <ClientLogin {...props} />}
          />

          <Route
            exact
            path="/:reseller_url?/client"
            component={(props) => <ClientLogin {...props} />}
          />
          <Route
            exact
            path="/:reseller_url?/client/documents"
            component={(props) => <ClientMain {...props} view="documents" />}
          />

          {/* END CLIENT ROUTES */}

          {/* START ADMIN ROUTES */}
          <Route
            exact
            path="/nsaccess"
            component={(props) => <AdminLogin {...props} />}
          />
          <Route
            exact
            path="/admin_dashboard"
            component={(props) => (
              <AdminMain {...props} view="admin_dashboard" />
            )}
          />
          <Route
            exact
            path="/clients_admin"
            component={(props) => <AdminMain {...props} view="clients_admin" />}
          />
          <Route
            exact
            path="/admin_services"
            component={(props) => (
              <AdminMain {...props} view="admin_services" />
            )}
          />
          <Route
            exact
            path="/admin_agreements"
            component={(props) => (
              <AdminMain {...props} view="admin_agreements" />
            )}
          />
          <Route
            exact
            path="/admin_agreements/addagreement"
            component={(props) => <AdminMain {...props} view="addagreement" />}
          />
          <Route
            exact
            path="/admin_agreements/editagreement/:id"
            component={(props) => <AdminMain {...props} view="editagreement" />}
          />
          <Route
            exact
            path="/reports_admin/:api_url/"
            component={(props) => <AdminMain {...props} view="reports_admin" />}
          />
          <Route
            exact
            path="/admin_downloads/:id"
            component={(props) => (
              <AdminMain {...props} view="admin_downloads" />
            )}
          />
          {/* END ADMIN ROUTES */}

          {/* DYNAMIC FRONT END CUSTOMER ROUTES */}
          <Route exact path="/" component={(props) => <Login {...props} />} />
          <Route
            exact
            path="/:reseller_url?"
            component={(props) => <Login {...props} />}
          />

          <Route
            exact
            path="/:reseller_hash?/register"
            component={(props) => <Register {...props} />}
          />

          <Route
            exact
            path="/:reseller_url?/:reseller_hash?/register"
            component={(props) => <Register {...props} />}
          />

          <Route
            exact
            path="/:reseller_hash?/:action_type?/register"
            component={(props) => <Register {...props} />}
          />

          <Route
            exact
            path="/:reseller_url?/:reseller_hash?/:action_type?/register"
            component={(props) => <Register {...props} />}
          />
          {/* DYNAMIC FRONT END CUSTOMER ROUTES */}

          <Route component={Error404} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
