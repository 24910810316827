import { React, createRef } from "react";
import { Component } from "react";
import { connect } from "react-redux";

import {
  listClients,
  listServicesGroups,
  listAssignedServices,
  assignServicesToCompany,
  assignBundlesToCompany,
  getClientKycDetail,
  emailKycDetails,
  initiateClientKyc,
  getCreditApplications,
  listClientAgreements,
  updateClientAllowedServices,
  getClientInfo,
  editClient,
  sendClientInvite,
  deleteInviteClient,
} from "./../../../config/api_calls";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import $ from "jquery";

import { addSettings, addMsaInfo } from "../../../actions";
import DataTable from "../../../components/Tables/DataTable";
import { Link } from "react-router-dom";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/FormLoader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";

import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import MutextField from "@material-ui/core/TextField";
import dateFormat, { masks } from "dateformat";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import TextField from "../../../components/InputFields/TextField";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/build/index.css";
import { PhoneNumberUtil } from "google-libphonenumber";
import Select from "react-select";
import countryList from "react-select-country-list";
import { ValidateEmail } from "../../../config/utility";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { parsePhoneNumberFromString } from "libphonenumber-js";

import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  columnsClients,
  COMPANY_NAME,
  KYC_URL,
  APP_LIVE_URL,
} from "./../../../config/config";
const auth = new HelperClass();
class Clients extends Component {
  constructor() {
    super();
    this.state = {
      tableData: [],
      tableLoader: true,
      showServiceLoader: true,

      value: "",
      didArray: [],
      tfnArray: [],
      termArray: [],
      tfnTermArray: [],
      teamsArray: [],
      azTermArray: [],
      liveAgentArray: [],
      cnamArray: [],
      smsArray: [],

      did_service: "",
      tfn_service: "",
      term_service: "",
      sms_service: "",
      teams_service: "",
      tfn_term_service: "",
      az_term_service: "",
      liveagent_service: "",
      cnam_service: "",

      projectionDetails: [],
      creditErrorMessage: "",
      creditSuccessMessage: "",
      processing_request: false,
      credit_applications: [],

      did_service_date: new Date().toISOString().slice(0, 10),
      tfn_service_date: new Date().toISOString().slice(0, 10),
      term_service_date: new Date().toISOString().slice(0, 10),
      sms_service_date: new Date().toISOString().slice(0, 10),
      teams_service_date: new Date().toISOString().slice(0, 10),
      tfn_term_service_date: new Date().toISOString().slice(0, 10),
      az_term_service_date: new Date().toISOString().slice(0, 10),
      liveagent_service_date: new Date().toISOString().slice(0, 10),
      cnam_service_date: new Date().toISOString().slice(0, 10),
      bundle_date: new Date().toISOString().slice(0, 10),

      client_id: "",

      kyc_details: [],
      kyc_key: "",
      steps_allowed: 0,
      steps_completed: 0,
      completed_percentage: 0,
      loading_button: false,
      kycErrorMessage: "",
      kycSuccessMessage: "",
      client_register_date: "",

      serviceData: [],
      bundleData: [],
      serviceAssignedData: [],
      servicesQueue: [],
      bundlesQueue: [],
      company_id: "",
      company_title: "",
      errorMessage: "",
      successMessage: "",
      assignErrorMessage: "",
      assignSuccessMessage: "",
      disabled: false,
      nrc_did: "",
      mrc_did: "",
      rate_did: "",
      nrc_tfn: "",
      mrc_tfn: "",
      rate_tfn: "",
      rate_term: "",
      rate_smsin: 0,
      rate_smsout: 0,
      errorMessage: "",
      extra_details: [],
      packagesAssignedData: [],
      emptyService: 0,

      agreementErrorMessage: "",
      agreementSuccessMessage: "",
      agreementListing: [],

      checkAll: false,
      assignedErrorMessage: "",
      assignedSuccessMessage: "",
      client_accountno: "",

      update_client_account_no: "",
      client_comp_name: "",
      client_update_name: "",
      client_title: "",
      client_update_email: "",
      client_phone: "",
      client_address: "",
      client_address2: "",
      client_country: "",
      client_city: "",
      client_state: "",
      client_zip: "",
      client_password: "",
      client_confirm_password: "",
      client_account_status: "",
      errorClientMessageEdit: "",
      successClientMessageEdit: "",
      errorClientTitle: "",
      errorClientEmail: "",
      errorClientPhone: "",
      errorClientPass: "",
      errorClientAddress: "",
      errorClientCountry: "",
      errorClientCity: "",
      errorClientState: "",
      errorClientZip: "",
      errorClientMessage: "",
      errorClientMessageEdit: "",
      notifyCheckBox: false,
      statusChanged: false,

      useGooglePlaces: true,
      toggleButtonText: "Allow PO Box Address",

      clientErrorMessage: "",
      clientSuccessMessage: "",
      client_name: "",
      client_email: "",
      invitation_comment: "",
      tableData_invitedClients: [],
      activeTabCP: "tabItem7",
      clientInviteErrorMessage: "",
      clientInviteSuccessMessage: "",

      columnServices: [
        { name: "ID" },
        { name: "NAME" },
        { name: "COMPANY" },
        {
          name: "PHONE",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.formatPhoneNumber(value)}</div>;
            },
          },
        },
        { name: "EMAIL" },
        {
          name: "DATE",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date(value)}</div>;
            },
          },
        },
        {
          name: "STATUS",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.defaultText(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "ACTIONS",
          options: {
            customBodyRender: (value, tableMeta) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            className="dropdown-toggle btn btn-icon btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              {/*<li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.showServices(value, tableMeta)
                                  }
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                  title="Add/Edit rates"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-file-docs"></em>
                                  <span>Assign Rates</span>
                                </a>
                              </li>*/}
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.editClientModal(value, tableMeta)
                                  }
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                  title="Edit Client"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-edit"></em>
                                  <span>Edit Client</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.showAssignedServices(value, tableMeta)
                                  }
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                  title="Assigned Services"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-file-docs"></em>
                                  <span>Assigned Products</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.clientAgreements(value, tableMeta)
                                  }
                                  title="Client Agreements"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-file-docs"></em>
                                  <span>Client Agreements</span>
                                </a>
                              </li>
                              {/*<li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.clientServices(value, tableMeta, 1)
                                  }
                                  title="Client Services"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-file-docs"></em>
                                  <span>Client Settings</span>
                                </a>
                                </li>
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.showDetails(value, tableMeta)
                                  }
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                  title="Other KYC Details"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-menu"></em>
                                  <span>KYC Form</span>
                                </a>
                              </li>*/}
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.showCreditApplications(
                                      value,
                                      tableMeta
                                    )
                                  }
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                  title="Credit Applications"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-coin"></em>
                                  <span>Credit Applications</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
      columnServices_invitedClients: [
        { name: "CLIENT NAME" },
        { name: "CLIENT EMAIL" },
        { name: "AGENT NAME" },
        { name: "AGENT EMAIL" },
        {
          name: "INVITED ON",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date(value)}</div>;
            },
            filter: false,
          },
        },
        { name: "AGENT ID", options: { display: false } },
        { name: "ID", options: { display: false } },
        {
          name: "Comment",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div onClick={() => this.assignedTo(value, tableMeta)}>
                  <span
                    className="badge badge-outline-secondary"
                    style={{ cursor: "pointer" }}
                  >
                    View
                  </span>
                </div>
              );
            },
          },
        },
        {
          name: "Actions",
          options: {
            customBodyRender: (value, tableMeta) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            className="dropdown-toggle btn btn-icon btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  onClick={() =>
                                    this.deleteAlert(tableMeta.rowData[6])
                                  }
                                  style={{ cursor: "pointer" }}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Delete Agreement"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-trash"></em>
                                  <span>Delete Client</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  onClick={() =>
                                    this.resendInvite(
                                      tableMeta.rowData[1],
                                      tableMeta.rowData[0],
                                      tableMeta.rowData[7]
                                    )
                                  }
                                  style={{ cursor: "pointer" }}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Resend Activation"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-signin"></em>
                                  <span>Resend Invite</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
    };
    this.phoneInputRef = createRef();
    this.countryOptions = countryList()
      .getData()
      .filter(
        (country) => country.label !== "United States Minor Outlying Islands"
      );
  }
  async componentDidMount() {
    const clientsResponce = await listClients(
      auth.getAccount(),
      auth.getToken()
    );

    if (
      clientsResponce.data.status === 403 ||
      clientsResponce.data.errors === "authentication missing" ||
      clientsResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      clientsResponce.data.status === 404 ||
      clientsResponce.data.note === "missing"
    ) {
      // window.location.replace("/error");
      console.log("settings missing");
      this.setState({
        tableLoader: false,
      });
    } else if (
      clientsResponce.data.status === 200 &&
      clientsResponce.data.message === "success"
    ) {
      this.setState({
        tableData: clientsResponce.data.data,
        tableData_invitedClients: clientsResponce.data.invited_clients,
        tableLoader: false,
      });
    } else {
      window.location.replace("/error");
    }
  }
  handleToggle = () => {
    this.setState((prevState) => ({
      useGooglePlaces: !prevState.useGooglePlaces,
      toggleButtonText:
        prevState.toggleButtonText === "Allow PO-Box Address"
          ? "Allow Simple Address"
          : "Allow PO-Box Address",
    }));
  };

  showCreditApplications = async (value, tableMeta) => {
    let client_accountno = tableMeta.rowData[0];
    this.setState({
      client_accountno: client_accountno,
      credit_applications: [],
    });
    const kycResponce = await getCreditApplications(
      auth.getAccount(),
      auth.getToken(),
      client_accountno
    );
    console.log("showCreditApplications: ", kycResponce.data);
    if (
      kycResponce.data.status === 403 ||
      kycResponce.data.errors === "authentication missing" ||
      kycResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
      return;
    } else if (kycResponce.data.status === 406) {
      this.setState({
        creditErrorMessage:
          "There is an error while getting the credit applications. Please try again later.",
        processing_request: false,
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 404 &&
      kycResponce.data.message === "not_exists"
    ) {
      this.setState({
        creditErrorMessage: "",
        processing_request: false,
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 200 &&
      kycResponce.data.message === "success"
    ) {
      this.setState({
        credit_applications: kycResponce.data.data,
        processing_request: false,
      });
    } else {
      this.setState({
        creditErrorMessage: "",
        processing_request: false,
      });
    }
    window.$("#modalCreditApplications").modal("show");
  };

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy");
  };

  defaultText = (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let is_default = value;
    if (is_default === "inactive") {
      return (
        <span className="badge badge-outline-danger">
          <em className="icon ni ni-signin"></em> Inactive
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Active
        </span>
      );
    }
  };

  formatPhoneNumber = (phoneNumber) => {
    const phoneNumberObj = parsePhoneNumberFromString(phoneNumber);
    if (phoneNumberObj) {
      if (phoneNumberObj.country === "US") {
        const nationalNumber = phoneNumberObj
          .formatNational()
          .replace(/\D/g, ""); // e.g. "2123727200"
        const areaCode = nationalNumber.slice(0, 3);
        const firstPart = nationalNumber.slice(3, 6);
        const secondPart = nationalNumber.slice(6);
        return `(${areaCode}) ${firstPart}-${secondPart}`;
      }
      return phoneNumberObj.formatInternational();
    }
    return phoneNumber; // Return original number if parsing fails
  };

  clientAgreements = async (value, tableMeta) => {
    this.setState({
      company_id: tableMeta.rowData[0],
      company_title: tableMeta.rowData[1],
      agreementErrorMessage: "",
      agreementSuccessMessage: "",
      agreementListing: [],
    });
    const servicesResponce = await listClientAgreements(
      auth.getAccount(),
      auth.getToken(),
      tableMeta.rowData[0]
    );
    console.log("clientAgreements: ", servicesResponce.data);

    window.$("#modalClientAgreements").modal("show");
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 406) {
      this.setState({
        agreementErrorMessage:
          "Services/rates are not added to your account. Please add proper services/rates.",
      });
      //window.location.replace("/error");
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        agreementErrorMessage:
          "There is some error while assigning markup rates.",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        agreementListing: servicesResponce.data.clientAgreementsInfo,
        agreementErrorMessage: "",
      });
      this.renderServices();
    } else {
      this.setState({
        agreementErrorMessage:
          "There is some error while assigning markup rates.",
      });
      //window.location.replace("/error");
    }
    //window.$("#modalClientAgreements").modal("show");
  };

  handleDateChangeDID = async (date) => {
    this.setState({
      did_service_date: date.toISOString().slice(0, 10),
    });
  };

  handleDateChangeTFN = async (date) => {
    this.setState({
      tfn_service_date: date.toISOString().slice(0, 10),
    });
  };

  handleDateChangeTERM = async (date) => {
    this.setState({
      term_service_date: date.toISOString().slice(0, 10),
    });
  };

  handleDateChangeTEAMS = async (date) => {
    this.setState({
      teams_service_date: date.toISOString().slice(0, 10),
    });
  };

  handleDateChangeTfnTerm = async (date) => {
    this.setState({
      tfn_term_service_date: date.toISOString().slice(0, 10),
    });
  };

  handleDateChangeAzTerm = async (date) => {
    this.setState({
      az_term_service_date: date.toISOString().slice(0, 10),
    });
  };

  handleDateChangeSMS = async (date) => {
    this.setState({
      sms_service_date: date.toISOString().slice(0, 10),
    });
  };

  handleDateChangeLA = async (date) => {
    this.setState({
      liveagent_service_date: date.toISOString().slice(0, 10),
    });
  };

  handleDateChangeCnam = async (date) => {
    this.setState({
      cnam_service_date: date.toISOString().slice(0, 10),
    });
  };

  handleDateChangeBundle = async (date) => {
    this.setState({
      bundle_date: date.toISOString().slice(0, 10),
    });
  };

  showServices = async (value, tableMeta) => {
    this.setState({
      company_id: tableMeta.rowData[0],
      company_title: tableMeta.rowData[1],
      errorMessage: "",
      successMessage: "",
      assignSuccessMessage: "",
      assignErrorMessage: "",
      emptyService: 0,
    });
    const servicesResponce = await listServicesGroups(
      auth.getAccount(),
      auth.getToken(),
      tableMeta.rowData[0]
    );
    console.log("showServices: ", servicesResponce.data.data);

    window.$("#modalFormDetail").modal("show");
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 406) {
      this.setState({
        errorMessage:
          "Services/rates are not added to your account. Please add proper services/rates.",
        emptyService: 1,
      });
      //window.location.replace("/error");
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while assigning markup rates.",
        emptyService: 1,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        serviceData: servicesResponce.data.data.servicesList,
        bundleData: servicesResponce.data.data.bundleList,
        //alreadyInQueue: servicesResponce.data.data.in_queue,
        servicesQueue: servicesResponce.data.data.services_queue,
        bundlesQueue: servicesResponce.data.data.bundle_queue,
        errorMessage: "",
      });
      this.renderServices();
    } else {
      this.setState({
        errorMessage: "There is some error while assigning markup rates.",
        emptyService: 1,
      });
      //window.location.replace("/error");
    }
  };

  showAssignedServices = async (value, tableMeta) => {
    this.setState({
      company_id: tableMeta.rowData[0],
      company_title: tableMeta.rowData[1],
      client_accountno: tableMeta.rowData[0],
      showServiceLoader: true,
    });
    const servicesResponce = await listAssignedServices(
      auth.getAccount(),
      auth.getToken(),
      tableMeta.rowData[0]
    );

    console.log("servicesResponce.data.data: ", servicesResponce.data);
    window.$("#modalAssignedServices").modal("show");
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        serviceAssignedData: [],
        showServiceLoader: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        serviceAssignedData: servicesResponce.data.data,
        packagesAssignedData: servicesResponce.data.packagesList,
        projectionDetails: servicesResponce.data.projectionDetails,
        showServiceLoader: false,
      });
    } else {
      this.setState({
        serviceAssignedData: [],
        showServiceLoader: false,
      });
    }
  };

  handleCheckboxChange = (id) => {
    const updatedServiceAssignedData = this.state.serviceAssignedData.map(
      (service) =>
        service.id === id ? { ...service, checked: !service.checked } : service
    );
    this.setState({ serviceAssignedData: updatedServiceAssignedData }, () => {
      console.log(
        "Updated serviceAssignedData:",
        this.state.serviceAssignedData
      );
    });
  };

  handleCheckAllChange = () => {
    const { checkAll, serviceAssignedData } = this.state;
    const updatedCheckboxes = serviceAssignedData.map((checkbox) => ({
      ...checkbox,
      checked: !checkAll,
    }));
    this.setState(
      {
        serviceAssignedData: updatedCheckboxes,
        checkAll: !checkAll,
      },
      () => {
        console.log(
          "Updated serviceAssignedData:",
          this.state.serviceAssignedData
        );
      }
    );
  };

  updateAssignedServices = async () => {
    const { serviceAssignedData, client_accountno } = this.state;
    this.setState({
      update_service_button: true,
    });
    console.log(
      "updateAssignedServices serviceAssignedData: ",
      serviceAssignedData
    );
    const servicesResponce = await updateClientAllowedServices(
      auth.getAccount(),
      auth.getToken(),
      client_accountno,
      JSON.stringify(serviceAssignedData)
    );
    console.log("updateAssignedServices: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        update_service_button: false,
        assignedErrorMessage:
          "There is some error while assigning the services to the client.",
        assignedSuccessMessage: "",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        assignedErrorMessage: "",
        assignedSuccessMessage:
          "Services / Products assigned successfully to the client.",
        update_service_button: false,
      });
    } else {
      this.setState({
        update_service_button: false,
        assignedErrorMessage:
          "There is some error while assigning the services to the client.",
        assignedSuccessMessage: "",
      });
    }
    setTimeout(() => {
      this.setState({
        update_service_button: false,
        assignedErrorMessage: "",
        assignedSuccessMessage: "",
      });
    }, 4000);
  };

  showDetails = async (value, tableMeta) => {
    let extra_details = tableMeta.rowData[0];
    this.setState({
      company_id: tableMeta.rowData[0],
      company_title: tableMeta.rowData[1],
      kyc_details: [],
      kyc_key: "",
      steps_allowed: 0,
      steps_completed: 0,
      completed_percentage: 0,
      client_accountno: extra_details,
      kycErrorMessage: "",
      loading_button: false,
      kycSuccessMessage: "",
    });

    const kycResponce = await getClientKycDetail(
      auth.getAccount(),
      auth.getToken(),
      extra_details
    );
    console.log("getClientKycDetail: ", kycResponce.data);
    if (
      kycResponce.data.status === 403 ||
      kycResponce.data.errors === "authentication missing" ||
      kycResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
      return;
    } else if (kycResponce.data.status === 406) {
      this.setState({
        kycErrorMessage:
          "There is an error while getting client details. Please try again later.",
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 404 &&
      kycResponce.data.message === "not_exists"
    ) {
      this.setState({
        kycErrorMessage: "",
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 200 &&
      kycResponce.data.message === "success"
    ) {
      let completed_percentage =
        (100 / kycResponce.data.steps_allowed) *
        kycResponce.data.steps_completed;
      this.setState({
        kyc_details: kycResponce.data.kyc_data.data,
        kyc_key: kycResponce.data.kyc_key,
        steps_allowed: kycResponce.data.steps_allowed,
        steps_completed: kycResponce.data.steps_completed,
        completed_percentage: completed_percentage.toFixed(0),
        client_register_date: this.format_date(
          kycResponce.data.kyc_data.register_date
        ),
      });
    } else {
      this.setState({
        kycErrorMessage: "",
      });
    }
    window.$("#modalClientDetails").modal("show");
  };

  email_kyc_Details = async () => {
    const { client_accountno } = this.state;
    this.setState({
      kycErrorMessage: "",
      disabled: true,
      kycSuccessMessage: "",
    });
    const kycResponce = await emailKycDetails(
      auth.getAccount(),
      auth.getToken(),
      client_accountno
    );
    console.log("emailKycDetails: ", kycResponce.data);
    if (
      kycResponce.data.status === 403 ||
      kycResponce.data.errors === "authentication missing" ||
      kycResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
      return;
    } else if (kycResponce.data.status === 406) {
      this.setState({
        kycErrorMessage:
          "There is an error while sending an email. Please try again later.",
        disabled: false,
        kycSuccessMessage: "",
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 404 &&
      kycResponce.data.message === "not_exists"
    ) {
      this.setState({
        kycErrorMessage:
          "There is an error while sending an email. Please try again.",
        disabled: false,
        kycSuccessMessage: "",
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 200 &&
      kycResponce.data.message === "success"
    ) {
      this.setState({
        disabled: false,
        kycErrorMessage: "",
        kycSuccessMessage:
          "An email has been sent to the customer with URL to complete his profile.",
      });
    } else {
      this.setState({
        kycErrorMessage:
          "There is an error while sending an email. Please try again.",
        disabled: false,
        kycSuccessMessage: "",
      });
    }
    setTimeout(() => {
      this.setState({ kycErrorMessage: "", kycSuccessMessage: "" });
    }, 5000);

    //window.$("#modalClientDetails").modal("show");
  };

  initiateKyc = async () => {
    const { client_accountno } = this.state;
    this.setState({
      kycErrorMessage: "",
      loading_button: true,
      kycSuccessMessage: "",
    });
    const kycResponce = await initiateClientKyc(
      auth.getAccount(),
      auth.getToken(),
      client_accountno
    );
    console.log("initiateKyc: ", kycResponce.data);
    if (
      kycResponce.data.status === 403 ||
      kycResponce.data.errors === "authentication missing" ||
      kycResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
      return;
    } else if (kycResponce.data.status === 406) {
      this.setState({
        kycErrorMessage:
          "There is an error while getting client details. Please try again later.",
        loading_button: false,
        kycSuccessMessage: "",
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 404 &&
      kycResponce.data.message === "not_exists"
    ) {
      this.setState({
        kycErrorMessage:
          "There is an error while getting the data. Please try again.",
        loading_button: false,
        kycSuccessMessage: "",
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 200 &&
      kycResponce.data.message === "success"
    ) {
      this.setState({
        loading_button: false,
        kycErrorMessage: "",
        kycSuccessMessage:
          "Kyc process initiated successfully. An email has also been sent to the customer with URL.",
      });
      setTimeout(function () {
        window.$("#modalClientDetails").modal("hide");
      }, 3000);
    } else {
      this.setState({
        kycErrorMessage:
          "There is an error while getting the data. Please try again.",
        loading_button: false,
        kycSuccessMessage: "",
      });
    }

    //window.$("#modalClientDetails").modal("show");
  };

  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };

  handleChange = async (event) => {
    const { target } = event;
    let value = target.type === "radio" ? target.checked : target.value;
    const { name } = target;
    //console.log(name);
    if (name === "client_update_name") {
      value = value.replace(/[^a-zA-Z\s'.,-]/g, "");
    } else if (name === "client_comp_name") {
      value = value.replace(/[^a-zA-Z0-9\s\-_.,&()]/g, "");
    } else if (name === "client_title") {
      value = value.replace(/[^a-zA-Z0-9\s\-_,&().]/g, "");
    }
    this.setState({
      [name]: value,
    });
    console.log("handleChange: ", name + " " + value);
  };

  renderServices = () => {
    let service_data = this.state.serviceData;
    let bundle_data = this.state.bundleData;
    console.log("service_data: ", service_data);
    let didArray = [];
    let tfnArray = [];
    let termArray = [];
    let smsArray = [];

    let tfnTermArray = [];
    let teamsArray = [];
    let azTermArray = [];
    let liveAgentArray = [];
    let cnamArray = [];

    for (let i = 0; i < service_data.length; i++) {
      console.log("service_data[i]['service_type']: ", service_data[i]);

      if (service_data[i]["service_type"] === "did") {
        let innerArray = [
          service_data[i]["id"],
          service_data[i]["service_title"],
          service_data[i]["service_type"],
          service_data[i]["nrc"],
          service_data[i]["mrc"],
          service_data[i]["rate"],
          service_data[i]["per_min_type"],
          service_data[i]["sms_in"],
          service_data[i]["sms_out"],
          service_data[i]["main_type"],
        ];
        didArray.push(innerArray);
      }
      if (service_data[i]["service_type"] === "tfn") {
        let innerArray2 = [
          service_data[i]["id"],
          service_data[i]["service_title"],
          service_data[i]["service_type"],
          service_data[i]["nrc"],
          service_data[i]["mrc"],
          service_data[i]["rate"],
          service_data[i]["per_min_type"],
          service_data[i]["sms_in"],
          service_data[i]["sms_out"],
          service_data[i]["main_type"],
        ];
        tfnArray.push(innerArray2);
      }
      if (service_data[i]["service_type"] === "term") {
        let innerArray3 = [
          service_data[i]["id"],
          service_data[i]["service_title"],
          service_data[i]["service_type"],
          service_data[i]["nrc"],
          service_data[i]["mrc"],
          service_data[i]["rate"],
          service_data[i]["per_min_type"],
          service_data[i]["sms_in"],
          service_data[i]["sms_out"],
          service_data[i]["main_type"],
        ];
        termArray.push(innerArray3);
      }
      if (service_data[i]["service_type"] === "sms") {
        let innerArray4 = [
          service_data[i]["id"],
          service_data[i]["service_title"],
          service_data[i]["service_type"],
          service_data[i]["nrc"],
          service_data[i]["mrc"],
          service_data[i]["rate"],
          service_data[i]["per_min_type"],
          service_data[i]["sms_in"],
          service_data[i]["sms_out"],
          service_data[i]["main_type"],
        ];
        smsArray.push(innerArray4);
      }
      if (service_data[i]["service_type"] === "tfn_term") {
        let innerArray5 = [
          service_data[i]["id"],
          service_data[i]["service_title"],
          service_data[i]["service_type"],
          service_data[i]["nrc"],
          service_data[i]["mrc"],
          service_data[i]["rate"],
          service_data[i]["per_min_type"],
          service_data[i]["sms_in"],
          service_data[i]["sms_out"],
          service_data[i]["main_type"],
        ];
        tfnTermArray.push(innerArray5);
      }
      if (service_data[i]["service_type"] === "teams") {
        let innerArray6 = [
          service_data[i]["id"],
          service_data[i]["service_title"],
          service_data[i]["service_type"],
          service_data[i]["nrc"],
          service_data[i]["mrc"],
          service_data[i]["rate"],
          service_data[i]["per_min_type"],
          service_data[i]["sms_in"],
          service_data[i]["sms_out"],
          service_data[i]["main_type"],
        ];
        teamsArray.push(innerArray6);
      }
      if (service_data[i]["service_type"] === "az_term") {
        let innerArray7 = [
          service_data[i]["id"],
          service_data[i]["service_title"],
          service_data[i]["service_type"],
          service_data[i]["nrc"],
          service_data[i]["mrc"],
          service_data[i]["rate"],
          service_data[i]["per_min_type"],
          service_data[i]["sms_in"],
          service_data[i]["sms_out"],
          service_data[i]["main_type"],
        ];
        azTermArray.push(innerArray7);
      }
      if (service_data[i]["service_type"] === "live_agent") {
        let innerArray8 = [
          service_data[i]["id"],
          service_data[i]["service_title"],
          service_data[i]["service_type"],
          service_data[i]["nrc"],
          service_data[i]["mrc"],
          service_data[i]["rate"],
          service_data[i]["per_min_type"],
          service_data[i]["sms_in"],
          service_data[i]["sms_out"],
          service_data[i]["main_type"],
        ];
        liveAgentArray.push(innerArray8);
      }
      if (service_data[i]["service_type"] === "cnam") {
        let innerArray9 = [
          service_data[i]["id"],
          service_data[i]["service_title"],
          service_data[i]["service_type"],
          service_data[i]["nrc"],
          service_data[i]["mrc"],
          service_data[i]["rate"],
          service_data[i]["per_min_type"],
          service_data[i]["sms_in"],
          service_data[i]["sms_out"],
          service_data[i]["main_type"],
        ];
        cnamArray.push(innerArray9);
      }
    }
    this.setState({
      didArray: didArray,
      tfnArray: tfnArray,
      termArray: termArray,
      smsArray: smsArray,
      tfnTermArray: tfnTermArray,
      teamsArray: teamsArray,
      azTermArray: azTermArray,
      liveAgentArray: liveAgentArray,
      cnamArray: cnamArray,
    });
  };

  setPriceStates = async (
    did_service,
    tfn_service,
    term_service,
    sms_service
  ) => {
    if (did_service != "") {
      let did_array = this.state.didArray;
      for (let i = 0; i < did_array.length; i++) {
        if (did_array[i][0] == did_service) {
          this.setState({
            nrc_did: did_array[i][3],
            mrc_did: did_array[i][4],
            rate_did: did_array[i][6],
          });
        }
      }
    }
    if (tfn_service != "") {
      let tfn_array = this.state.tfnArray;
      for (let j = 0; j < tfn_array.length; j++) {
        if (tfn_array[j][0] == tfn_service) {
          this.setState({
            nrc_tfn: tfn_array[j][3],
            mrc_tfn: tfn_array[j][4],
            rate_tfn: tfn_array[j][6],
          });
        }
      }
    }
    if (term_service != "") {
      let term_array = this.state.termArray;
      for (let k = 0; k < term_array.length; k++) {
        if (term_array[k][0] == term_service) {
          //console.log("HERE TERM: ", term_array[k][3]);
          this.setState({
            rate_term: term_array[k][6],
          });
        }
      }
    }

    if (sms_service != "") {
      let sms_array = this.state.smsArray;
      for (let k = 0; k < sms_array.length; k++) {
        if (sms_array[k][0] == sms_service) {
          //console.log("HERE TERM: ", sms_array[k][10]);
          this.setState({
            rate_smsin: sms_array[k][10],
            rate_smsout: sms_array[k][11],
          });
        }
      }
    }
  };

  handle_accordion_head = (number) => {
    if (number === 0) {
      return "accordion-head";
    } else {
      return "accordion-head collapsed";
    }
  };

  handle_accordion_body = (number) => {
    if (number === 0) {
      return "accordion-body collapse show";
    } else {
      return "accordion-body collapse";
    }
  };

  formSave = async () => {
    let {
      did_service,
      tfn_service,
      term_service,
      sms_service,
      teams_service,
      tfn_term_service,
      az_term_service,
      liveagent_service,
      cnam_service,
      did_service_date,
      tfn_service_date,
      term_service_date,
      sms_service_date,
      teams_service_date,
      tfn_term_service_date,
      az_term_service_date,
      liveagent_service_date,
      cnam_service_date,
      company_id,
    } = this.state;
    /*await this.setPriceStates(
      did_service,
      tfn_service,
      term_service,
      sms_service
    );*/

    this.setState({
      assignErrorMessage: "",
      assignSuccessMessage: "",
      disabled: true,
    });

    const saveResponce = await assignServicesToCompany(
      auth.getAccount(),
      auth.getToken(),
      did_service,
      tfn_service,
      term_service,
      sms_service,
      teams_service,
      tfn_term_service,
      az_term_service,
      liveagent_service,
      cnam_service,
      did_service_date,
      tfn_service_date,
      term_service_date,
      sms_service_date,
      teams_service_date,
      tfn_term_service_date,
      az_term_service_date,
      liveagent_service_date,
      cnam_service_date,
      company_id
    );
    console.log("Assign Result: ", saveResponce.data.data);
    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "fail" &&
      saveResponce.data.note !== ""
    ) {
      // window.location.replace("/error");
      this.setState({
        assignErrorMessage: saveResponce.data.note,
        assignSuccessMessage: "",
        disabled: false,
      });
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        assignErrorMessage: "",
        assignSuccessMessage: "Services assigned successfully.",
        disabled: false,
      });

      $("#viewBtn").show();
      $("#btnloader").hide();

      setTimeout(function () {
        window.$("#modalFormDetail").modal("hide");
      }, 3000);
      this.componentDidMount();
    } else {
      this.setState({
        assignErrorMessage: "There is some error while assigning the services.",
        assignSuccessMessage: "",
        disabled: false,
      });
      //window.location.replace("/error");
    }
  };

  assignBundles = async () => {
    let { bundle_date, company_id } = this.state;
    this.setState({
      assignErrorMessage: "",
      assignSuccessMessage: "",
      disabled: true,
    });
    var checkedValues = $("input:radio.vdrSelected:checked")
      .map(function () {
        return this.value;
      })
      .get();
    console.log("checkedValues: ", checkedValues[0]);
    if (checkedValues.length === 0) {
      this.setState({
        assignErrorMessage: "Please select the package.",
        assignSuccessMessage: "",
        disabled: false,
      });
    } else {
      this.setState({
        assignErrorMessage: "",
        assignSuccessMessage: "",
        disabled: true,
      });

      const saveResponce = await assignBundlesToCompany(
        auth.getAccount(),
        auth.getToken(),
        checkedValues[0],
        bundle_date,
        company_id
      );
      console.log("Assign Result: ", saveResponce.data.data);
      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        saveResponce.data.status === 404 &&
        saveResponce.data.message === "fail" &&
        saveResponce.data.note !== ""
      ) {
        // window.location.replace("/error");
        this.setState({
          assignErrorMessage: saveResponce.data.note,
          assignSuccessMessage: "",
          disabled: false,
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success"
      ) {
        this.setState({
          assignErrorMessage: "",
          assignSuccessMessage: "Package assigned successfully.",
          disabled: false,
        });

        $("#viewBtn").show();
        $("#btnloader").hide();

        setTimeout(function () {
          window.$("#modalFormDetail").modal("hide");
        }, 3000);
        this.componentDidMount();
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success_delete"
      ) {
        this.setState({
          assignErrorMessage: "",
          assignSuccessMessage:
            "Previous request is discarded and new package assigned successfully.",
          disabled: false,
        });

        $("#viewBtn").show();
        $("#btnloader").hide();

        setTimeout(function () {
          window.$("#modalFormDetail").modal("hide");
        }, 3000);
        this.componentDidMount();
      } else {
        this.setState({
          assignErrorMessage:
            "There is some error while assigning the package.",
          assignSuccessMessage: "",
          disabled: false,
        });
        //window.location.replace("/error");
      }
    }
  };

  downloadPdf = async (d_f_name) => {
    if (d_f_name !== "") {
      d_f_name = d_f_name + ".pdf";
      var url = APP_LIVE_URL + "files_data/kyc_signup/" + d_f_name;
      console.log("url: ", url);
      let file_name = d_f_name;
      fetch(url)
        .then((x) => x.blob())
        .then((b) => {
          // console.log("B INSTANCE", b instanceof Blob);
          const url = window.URL.createObjectURL(b);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = file_name;
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          this.setState({
            kycErrorMessage: "There is some error while downloading the PDF.",
          });
          setTimeout(() => {
            this.setState({ kycErrorMessage: "" });
          }, 4000);
          // Handle any errors that occurred during the fetch
          console.error("Fetch error:", error);
        });
    }
  };

  downloadCreditAppPdf = async (d_f_name) => {
    this.setState({ processing_request: true });
    var url = APP_LIVE_URL + "files_data/credit_application/" + d_f_name;
    let file_name = d_f_name;
    fetch(url)
      .then((x) => x.blob())
      .then((b) => {
        // console.log("B INSTANCE", b instanceof Blob);
        const url = window.URL.createObjectURL(b);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(url);
        this.setState({ processing_request: false });
      });
  };

  downloadAgreement = async (d_f_name, agreement_title) => {
    this.setState({ processing_request: true });
    var url = APP_LIVE_URL + "files_data/agreements/" + d_f_name;
    let file_name = agreement_title.replace(/[^a-zA-Z0-9]/g, "");
    fetch(url)
      .then((x) => x.blob())
      .then((b) => {
        // console.log("B INSTANCE", b instanceof Blob);
        const url = window.URL.createObjectURL(b);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(url);
        this.setState({ processing_request: false });
      });
  };

  editClientModal = async (value, tableMeta) => {
    let client_accountno = tableMeta.rowData[0];
    this.setState({
      client_accountno: client_accountno,
      credit_applications: [],
    });
    const getClientInfoResponce = await getClientInfo(
      auth.getAccount(),
      auth.getToken(),
      client_accountno
    );
    console.log("getClientInfoResponce.data : ", getClientInfoResponce.data);
    if (
      getClientInfoResponce.data.status === 403 ||
      getClientInfoResponce.data.errors === "authentication missing" ||
      getClientInfoResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      getClientInfoResponce.data.status === 404 ||
      getClientInfoResponce.data.note === "missing"
    ) {
      // window.location.replace("/error");
      console.log("settings missing");
      this.setState({
        tableLoader: false,
      });
    } else if (
      getClientInfoResponce.data.status === 200 &&
      getClientInfoResponce.data.message === "success"
    ) {
      window.$("#modalUpdateClient").modal("show");
      this.setState({
        update_client_account_no: client_accountno,
        client_comp_name: getClientInfoResponce.data.comp_name,
        client_update_name: getClientInfoResponce.data.name,
        client_title: getClientInfoResponce.data.title,
        client_update_email: getClientInfoResponce.data.email,
        client_phone: getClientInfoResponce.data.phone,
        client_address: getClientInfoResponce.data.address,
        client_address2: getClientInfoResponce.data.address2,
        client_country: getClientInfoResponce.data.country,
        client_city: getClientInfoResponce.data.city,
        client_state: getClientInfoResponce.data.state,
        client_zip: getClientInfoResponce.data.zip,
        client_account_status: getClientInfoResponce.data.account_status,
      });
    } else {
      // window.location.replace("/error");
    }
    // console.log('edit client account_no : ',tableMeta.rowData[0]);
  };

  formUpdateClient = async () => {
    let {
      client_accountno,
      client_title,
      client_update_name,
      client_update_email,
      client_password,
      client_confirm_password,
      client_phone,
      client_comp_name,
      client_state,
      client_address,
      client_city,
      client_zip,
      client_country,
      client_account_status,
      statusChanged,
      client_address2,
    } = this.state;

    const modalBody = document.getElementById("modalUpdateClient");
    if (modalBody) {
      setTimeout(() => {
        modalBody.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 0);
    }

    let is_validated = true;

    if (!client_comp_name || client_comp_name === "") {
      this.setState({
        errorClientCompName: "error",
        errorClientPhone: "",
        errorClientName: "",
        errorClientEmail: "",
        errorClientPass: "",
        errorClientMessageEdit: "Company name is required.",
      });
      is_validated = false;
    } else if (!client_update_name || client_update_name === "") {
      this.setState({
        errorClientPhone: "",
        errorClientCompName: "",
        errorClientEmail: "",
        errorClientPass: "",
        errorClientName: "error",
        errorClientMessageEdit: "Name is required.",
      });
      is_validated = false;
    } else if (!client_phone || client_phone === "") {
      this.setState({
        errorClientName: "",
        errorClientCompName: "",
        errorClientEmail: "",
        errorClientPass: "",
        errorClientPhone: "error",
        errorClientMessageEdit: "Phone is required.",
      });
      is_validated = false;
    } else if (ValidateEmail(client_update_email) === false) {
      this.setState({
        errorClientPhone: "",
        errorClientName: "",
        errorClientCompName: "",
        errorClientPass: "",
        errorClientEmail: "error",
        errorClientMessageEdit: "Invalid email format.",
      });
      is_validated = false;
    } else if (!client_address || client_address === "") {
      this.setState({
        errorClientPhone: "",
        errorClientName: "",
        errorClientCompName: "",
        errorClientEmail: "",
        errorClientPass: "",
        errorClientMessageEdit: "Address is required.",
      });
      is_validated = false;
    } else if (!client_city || client_city === "") {
      this.setState({
        errorClientPhone: "",
        errorClientName: "",
        errorClientCompName: "",
        errorClientEmail: "",
        errorClientPass: "",
        errorClientCity: "error",
        errorClientMessageEdit: "City is required.",
      });
      is_validated = false;
    } else if (!client_state || client_state === "") {
      this.setState({
        errorClientPhone: "",
        errorClientName: "",
        errorClientCompName: "",
        errorClientEmail: "",
        errorClientPass: "",
        errorClientCity: "",
        errorClientState: "error",
        errorClientMessageEdit: "State is required.",
      });
      is_validated = false;
    } else if (client_zip === null || client_zip === "") {
      this.setState({
        errorClientPhone: "",
        errorClientName: "",
        errorClientCompName: "",
        errorClientEmail: "",
        errorClientPass: "",
        errorClientCity: "",
        errorClientState: "",
        errorClientZip: "error",
        errorClientMessageEdit: "Zip/postal code is required.",
      });
      is_validated = false;
    } else if (!client_country || client_country === "") {
      this.setState({
        errorClientPhone: "",
        errorClientName: "",
        errorClientCompName: "",
        errorClientEmail: "",
        errorClientPass: "",
        errorClientMessageEdit: "Country is required.",
      });
      is_validated = false;
    } else if (client_password && client_password !== "") {
      if (client_password.length < 8) {
        this.setState({
          errorClientPhone: "",
          errorClientName: "",
          errorClientCompName: "",
          errorClientEmail: "",
          errorClientPass: "error",
          errorClientMessageEdit: "Use 8 characters or more for your password",
        });
        is_validated = false;
      } else if (client_confirm_password === "") {
        this.setState({
          errorClientPhone: "",
          errorClientName: "",
          errorClientCompName: "",
          errorClientEmail: "",
          errorClientPass: "error",
          errorClientMessageEdit: "Confirm password is required.",
        });
        is_validated = false;
      } else if (client_confirm_password.length < 8) {
        this.setState({
          errorClientPhone: "",
          errorClientName: "",
          errorClientCompName: "",
          errorClientEmail: "",
          errorClientPass: "error",
          errorClientMessageEdit:
            "Use 8 characters or more for your confirm password",
        });
        is_validated = false;
      } else if (client_password.trim() !== client_confirm_password.trim()) {
        this.setState({
          errorClientPhone: "",
          errorClientName: "",
          errorClientCompName: "",
          errorClientEmail: "",
          errorClientPass: "error",
          errorClientMessageEdit: "Password and confirm password do not match.",
        });
        is_validated = false;
      }
    } else if (client_account_status === "") {
      this.setState({
        errorMessageEdit: "Account Status is required.",
        disabled: false,
      });
      is_validated = false;
    }
    if (is_validated) {
      this.setState({
        errorClientPhone: "",
        errorClientName: "",
        errorClientCompName: "",
        errorClientEmail: "",
        errorClientPass: "",
      });
      let apiResponce = await editClient(
        auth.getAccount(),
        auth.getToken(),
        client_accountno,
        client_title,
        client_update_name,
        client_update_email,
        client_password,
        client_phone,
        client_comp_name,
        client_address,
        client_city,
        client_state,
        client_zip,
        client_country,
        this.state.notifyCheckBox.toString(),
        client_account_status,
        statusChanged,
        client_address2
      );
      console.log("Edit Client RES: ", apiResponce.data);
      if (
        apiResponce.data.status === 403 ||
        apiResponce.data.errors === "authentication missing" ||
        apiResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        apiResponce.data.status === 409 &&
        apiResponce.data.message === "Address validation failed"
      ) {
        const formattedErrorMessage = apiResponce.data.errors
          ? String(apiResponce.data.errors).replace(/,/g, "<br />")
          : "Address validation failed";

        this.setState({
          errorClientMessageEdit: formattedErrorMessage,
          tableLoader: false,
        });
      } else if (
        apiResponce.data.status === 200 &&
        apiResponce.data.message === "Success"
      ) {
        this.setState({
          successClientMessageEdit: "Client updated successfully.",
          errorClientMessageEdit: "",
          tableLoader: false,
        });
        setTimeout(async function () {
          //window.$("#modalFormDetail").modal("hide");
          window.location.reload();
        }, 3000);
      } else if (
        apiResponce.data.status === 404 &&
        apiResponce.data.message === "fail"
      ) {
        this.setState({
          errorClientMessageEdit: "Something went wrong,try again later.",
          successClientMessageEdit: "",
          disabled: false,
          tableLoader: false,
        });
        return false;
      } else {
        this.setState({
          errorClientMessageEdit: "Something went wrong,try again later.",
          successClientMessageEdit: "",
          disabled: false,
          tableLoader: false,
        });
        //auth.logout();
      }
    }
  };

  setNewClientSettings = async () => {
    this.setState({
      clientErrorMessage: "",
      clientSuccessMessage: "",
      client_name: "",
      client_email: "",
      invitation_comment: "",
    });
    window.$("#modalAddNewClient").modal("show");
  };

  saveNewClient = async () => {
    const { client_name, client_email, invitation_comment } = this.state;
    this.setState({
      clientErrorMessage: "",
      clientSuccessMessage: "",
      disabled: true,
    });

    if (client_name === "") {
      this.setState({
        clientErrorMessage: "Please enter the name.",
        clientSuccessMessage: "",
        disabled: false,
      });
    } else if (client_email === "") {
      this.setState({
        clientErrorMessage: "Please enter the email address.",
        clientSuccessMessage: "",
        disabled: false,
      });
    } else if (ValidateEmail(client_email) === false) {
      this.setState({
        clientErrorMessage: "Please enter the valid email address.",
        clientSuccessMessage: "",
        disabled: false,
      });
    } else if (invitation_comment === "") {
      this.setState({
        clientErrorMessage: "Please enter the comment.",
        clientSuccessMessage: "",
        disabled: false,
      });
    } else {
      const servicesResponce = await sendClientInvite(
        auth.getAccount(),
        auth.getToken(),
        client_email,
        client_name,
        invitation_comment
      );

      console.log("agentsResponce.data.data: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 409) {
        this.setState({
          clientErrorMessage:
            "An account with this email already exists. Invitation not sent.",
          clientSuccessMessage: "",
          disabled: false,
        });
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          clientErrorMessage:
            "There is some error while sending invite to the client.",
          clientSuccessMessage: "",
          disabled: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          clientErrorMessage: "",
          clientSuccessMessage: "Invite successfully sent to the client.",
          disabled: false,
          client_email: "",
          client_name: "",
          tableData_invitedClients: servicesResponce.data.invited_clients,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        this.setState({
          clientErrorMessage:
            "There is some error while sending invite to the client.",
          clientSuccessMessage: "",
          disabled: false,
        });
      }
    }
    setTimeout(() => {
      this.setState({
        clientErrorMessage: "",
        clientSuccessMessage: "",
      });
    }, 4000);
  };

  assignedTo = (value, tableMeta) => {
    let invitation_comment = tableMeta.rowData[7];
    this.setState(
      {
        invitation_comment: invitation_comment,
      },
      () => {
        window.$("#modalInvitationComment").modal("show");
      }
    );
  };

  deleteAlert = async (value) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let id = value;
    this.setState({
      delete_id: id,
    });
    window.$("#modalDeleteAlert").modal("show");
  };

  modalHideDel = () => {
    window.$("#modalDeleteAlert").modal("hide");
  };

  deleteInviteClient = async () => {
    let client_id = this.state.delete_id;

    this.setState({
      clientInviteErrorMessage: "",
      clientInviteSuccessMessage: "",
      disabled: true,
    });

    const userResponce = await deleteInviteClient(
      auth.getAccount(),
      auth.getToken(),
      client_id
    );

    console.log("deleteClientInvite: ", userResponce.data);

    if (
      userResponce.data.status === 403 ||
      userResponce.data.errors === "authentication missing" ||
      userResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      userResponce.data.status === 404 &&
      userResponce.data.message === "fail" &&
      userResponce.data.note !== ""
    ) {
      this.setState({
        clientInviteErrorMessage: "Error deleting the invited client.",
        clientInviteSuccessMessage: "",
        disabled: false,
      });
    } else if (
      userResponce.data.status === 200 &&
      userResponce.data.message === "success"
    ) {
      this.setState({
        clientInviteErrorMessage: "",
        clientInviteSuccessMessage: "Invited Client deleted successfully.",
        disabled: false,
      });

      setTimeout(function () {
        window.location.reload();
        window.$("#modalDeleteAlert").modal("hide");
      }, 3000);
    } else {
      this.setState({
        clientInviteErrorMessage: "Error deleting the invited client.",
        clientInviteSuccessMessage: "",
        disabled: false,
      });
    }
  };

  handleTabClick = (tabId) => {
    this.setState({ activeTabCP: tabId });
  };

  resendInvite = async (client_email, client_name, invitation_comment) => {
    this.setState({
      clientInviteErrorMessage: "",
      clientInviteSuccessMessage: "",
      disabled: true,
    });

    const servicesResponce = await sendClientInvite(
      auth.getAccount(),
      auth.getToken(),
      client_email,
      client_name,
      invitation_comment
    );

    window.$("#modalresendInvitation").modal("show");

    console.log("agentsResponce.data.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 409) {
      this.setState({
        clientErrorMessage:
          "An account with this email already exists. Invitation not sent.",
        clientSuccessMessage: "",
        disabled: false,
      });
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        clientInviteErrorMessage: "Error sending invitation email.",
        clientInviteSuccessMessage: "",
        disabled: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState(
        {
          clientInviteErrorMessage: "",
          clientInviteSuccessMessage: "Invitation email sent successfully.",
          disabled: false,
        },
        () => {
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      );
    } else {
      this.setState({
        clientInviteErrorMessage: "Error Sending Activation Email",
        clientInviteSuccessMessage: "",
        disabled: false,
      });
    }
  };

  modalHideResend = () => {
    window.$("#modalresendInvitation").modal("hide");
  };

  phoneUtil = PhoneNumberUtil.getInstance();

  isPhoneValid = (phone) => {
    try {
      return this.phoneUtil.isValidNumber(
        this.phoneUtil.parseAndKeepRawInput(phone)
      );
    } catch (error) {
      return false;
    }
  };

  handleFocus = () => {
    // Move cursor to the end of the input value
    const input = this.phoneInputRef.current;
    if (input) {
      const value = input.value;
      input.setSelectionRange(value.length, value.length);
    }
  };

  handleChangeCountry = (event) => {
    console.log("event.label: ", event.label);
    const country = event.label;
    this.setState({
      client_country: country,
    });
  };

  handleToggle = () => {
    this.setState((prevState) => ({
      useGooglePlaces: !prevState.useGooglePlaces,
      toggleButtonText:
        prevState.toggleButtonText === "Allow PO Box Address"
          ? "Allow Simple Address"
          : "Allow PO Box Address",
    }));
  };

  handleAddressSelect = async (place) => {
    const { description } = place.value;
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          description
        )}&key=${process.env.REACT_APP_ADDRESS_AUTOCOMPLETE_API_KEY}`
      );
      const data = await response.json();

      if (data.status === "OK") {
        const addressComponents = data.results[0].address_components;
        const formattedAddress = data.results[0].formatted_address;
        let city = null;
        let state = null;
        let country = null;
        let zipCode = null;
        let streetAddress = "";
        let neighborhood = null;
        let locality = null;
        let address2 = "";
        addressComponents.forEach((component) => {
          if (component.types.includes("neighborhood")) {
            neighborhood = component.long_name;
          } else if (component.types.includes("locality")) {
            locality = component.long_name;
          } else if (component.types.includes("administrative_area_level_1")) {
            state = component.short_name;
          } else if (component.types.includes("country")) {
            country = component.long_name;
          } else if (component.types.includes("postal_code")) {
            zipCode = component.long_name;
          } else if (component.types.includes("subpremise")) {
            address2 = component.long_name;
          } else if (
            ![
              "neighborhood",
              "sublocality",
              "sublocality_level_1",
              "sublocality_level_2",
              "administrative_area_level_2",
              "administrative_area_level_3",
              "postal_code_suffix",
            ].includes(component.types[0])
          ) {
            if (streetAddress !== "") {
              streetAddress += " ";
            }
            streetAddress += component.long_name;
          }
        });
        // Check if neighborhood is part of the formatted address
        if (neighborhood && formattedAddress.includes(neighborhood)) {
          city = neighborhood;
        } else {
          city = locality;
        }
        this.setState({
          client_city: city && city !== "" ? city : "",
          client_state: state && state !== "" ? state : "",
          client_zip: zipCode && zipCode !== "" ? zipCode : "",
          client_country: country && country !== "" ? country : "",
          client_address:
            streetAddress && streetAddress !== "" ? streetAddress : "",
          client_address2: address2 && address2 !== "" ? address2 : "",
        });
      } else {
        console.error("Reverse geocoding request failed:", data.status);
      }
    } catch (error) {
      console.error("Error fetching reverse geocoding data:", error);
    }
  };

  handleProjection = (service_id) => {
    const { projectionDetails } = this.state;
    let projection_data = "";

    var filteredArray = $.grep(projectionDetails, function (obj) {
      return obj.service_id === service_id;
    });
    console.table("projectionDetails: ", filteredArray);
    if (filteredArray && filteredArray.length > 0) {
      projection_data = filteredArray[0];
      return (
        <>
          <div class="coin-item" style={{ padding: "0px" }}>
            <div class="coin-info">
              <span class="coin-name">Monthly Usage</span>
              <span class="coin-text">{projection_data.monthly_usage}</span>
            </div>
          </div>
          <div class="coin-item" style={{ padding: "0px" }}>
            <div class="coin-info">
              <span class="coin-name">Total Numbers</span>
              <span class="coin-text">{projection_data.total_numbers}</span>
            </div>
          </div>
          <div class="coin-item" style={{ padding: "0px" }}>
            <div class="coin-info">
              <span class="coin-name">Usage Desc</span>
              <span class="coin-text">{projection_data.usage_description}</span>
            </div>
          </div>
          <div class="coin-item" style={{ padding: "0px" }}>
            <div class="coin-info">
              <span class="coin-name">Sessions</span>
              <span class="coin-text">{projection_data.sessions}</span>
            </div>
          </div>
        </>
      );
    } else {
      return "--";
    }
  };

  render() {
    const { useGooglePlaces, toggleButtonText } = this.state;
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {/* MSA WARNING  START*/}
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}
          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle name="Clients" icon="icon ni ni-users" />
                    </h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContentAgent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      <div
                        className="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul className="nk-block-tools g-3">
                          <li className="nk-block-tools-opt">
                            <a
                              onClick={() => this.setNewClientSettings()}
                              className="btn btn-primary"
                              style={{ color: "white" }}
                            >
                              <em className="icon ni ni-plus-round"></em>
                              &nbsp;Invite New Client
                            </a>
                          </li>
                          <li className="nk-block-tools-opt">
                            <a
                              href="clients/Addclient"
                              className="btn btn-primary"
                            >
                              <em className="icon ni ni-plus-round"></em>
                              &nbsp;Add New Client
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              {/* DETAIL MODAL START */}
              <div className="modal fade" tabIndex="-1" id="modalFormDetail">
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em className="icon ni ni-grid-add-c"></em> Assign
                          Services To{" "}
                          <sup>
                            <small className="text-soft">
                              {this.state.company_title}
                            </small>
                          </sup>
                        </strong>
                      </div>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body text-left">
                      {this.state.assignSuccessMessage !== "" ? (
                        <div className="example-alert">
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.assignSuccessMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.assignErrorMessage !== "" ? (
                        <div className="example-alert">
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.assignErrorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {/*<ul className="nav nav-tabs">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            data-toggle="tab"
                            href="#selectService"
                          >
                            <em className="icon ni ni-card-view"></em>
                            <span>Services</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#selectPackage"
                          >
                            <em className="icon ni ni-grid-add-c"></em>
                            <span>Packages</span>
                          </a>
                        </li>
                      </ul>*/}
                      <div className="tab-content">
                        <div className="tab-pane active" id="selectService">
                          <div
                            id="accordion-2"
                            className="accordion accordion-s3"
                          >
                            {this.state.servicesQueue &&
                            this.state.servicesQueue.length > 0 ? (
                              <div
                                class="example-alert"
                                style={{ marginBottom: "20px" }}
                              >
                                <div class="alert alert-warning alert-icon">
                                  <em class="icon ni ni-alert-circle"></em>{" "}
                                  <strong>Alert: </strong>This client already
                                  have a request to assign rates in our queue.
                                  If you assign the rates again, previous
                                  request will be discarded.{" "}
                                </div>
                              </div>
                            ) : null}

                            {this.state.didArray &&
                            this.state.didArray.length > 0 ? (
                              <div
                                className="accordion-item"
                                style={{
                                  borderBottom: "1px solid #dbdfea",
                                  paddingBottom: "10px",
                                }}
                              >
                                <a
                                  href="#"
                                  className="accordion-head"
                                  data-toggle="collapse"
                                  data-target="#accordion-item-2-1"
                                >
                                  <h6 className="title">DID Origination</h6>
                                  <span className="accordion-icon"></span>
                                </a>
                                <div
                                  className="accordion-body collapse show"
                                  id="accordion-item-2-1"
                                  data-parent="#accordion-2"
                                >
                                  <div className="accordion-inner">
                                    <div className="col-md-12 col-lg-12 col-xxl-12">
                                      <MutextField
                                        id="did_service"
                                        name="did_service"
                                        select
                                        label="Select DID Service"
                                        value={this.state.did_service}
                                        onChange={this.handleChange}
                                        SelectProps={{
                                          native: true,
                                        }}
                                        helperText="Please select the DID service."
                                        variant="outlined"
                                        fullWidth
                                      >
                                        <option value=""></option>
                                        {this.state.didArray.map(
                                          (didservices) => (
                                            <option
                                              value={
                                                didservices[0] +
                                                "###" +
                                                didservices[1]
                                              }
                                            >
                                              {didservices[1]}
                                            </option>
                                          )
                                        )}
                                      </MutextField>
                                    </div>
                                    <div className="col-md-12 col-lg-12 col-xxl-12">
                                      <div className="form-group">
                                        <MuiPickersUtilsProvider
                                          utils={DateFnsUtils}
                                        >
                                          <DatePicker
                                            id="did_service_date"
                                            name="did_service_date"
                                            type="text"
                                            format="yyyy-MM-dd"
                                            margin="normal"
                                            label="Select Publish Date"
                                            value={this.state.did_service_date}
                                            onChange={this.handleDateChangeDID}
                                            keyboardbuttonprops={{
                                              "aria-label": "change date",
                                              variant: "outlined",
                                            }}
                                            inputVariant="outlined"
                                            fullWidth
                                          />
                                        </MuiPickersUtilsProvider>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {this.state.tfnArray &&
                            this.state.tfnArray.length > 0 ? (
                              <div
                                className="accordion-item"
                                style={{
                                  borderBottom: "1px solid #dbdfea",
                                  paddingBottom: "10px",
                                  paddingTop: "10px",
                                }}
                              >
                                <a
                                  href="#"
                                  className="accordion-head collapsed"
                                  data-toggle="collapse"
                                  data-target="#accordion-item-2-2"
                                >
                                  <h6 className="title">
                                    Toll Free Origination
                                  </h6>
                                  <span className="accordion-icon"></span>
                                </a>
                                <div
                                  className="accordion-body collapse"
                                  id="accordion-item-2-2"
                                  data-parent="#accordion-2"
                                >
                                  <div className="accordion-inner">
                                    <div className="col-md-12 col-lg-12 col-xxl-12">
                                      <MutextField
                                        id="tfn_service"
                                        name="tfn_service"
                                        select
                                        label="Select TFN Service"
                                        value={this.state.tfn_service}
                                        onChange={this.handleChange}
                                        SelectProps={{
                                          native: true,
                                        }}
                                        helperText="Please select the TFN service."
                                        variant="outlined"
                                        fullWidth
                                      >
                                        <option value=""></option>
                                        {this.state.tfnArray.map(
                                          (tfnservices) => (
                                            <option
                                              value={
                                                tfnservices[0] +
                                                "###" +
                                                tfnservices[1]
                                              }
                                            >
                                              {tfnservices[1]}
                                            </option>
                                          )
                                        )}
                                      </MutextField>
                                    </div>
                                    <div className="col-md-12 col-lg-12 col-xxl-12">
                                      <div className="form-group">
                                        <MuiPickersUtilsProvider
                                          utils={DateFnsUtils}
                                        >
                                          <DatePicker
                                            id="tfn_service_date"
                                            name="tfn_service_date"
                                            format="yyyy-MM-dd"
                                            type="text"
                                            margin="normal"
                                            label="Select Publish Date"
                                            value={this.state.tfn_service_date}
                                            onChange={this.handleDateChangeTFN}
                                            keyboardbuttonprops={{
                                              "aria-label": "change date",
                                              variant: "outlined",
                                            }}
                                            fullWidth
                                            inputVariant="outlined"
                                          />
                                        </MuiPickersUtilsProvider>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {this.state.termArray &&
                            this.state.termArray.length > 0 ? (
                              <div
                                className="accordion-item"
                                style={{
                                  borderBottom: "1px solid #dbdfea",
                                  paddingBottom: "10px",
                                  paddingTop: "10px",
                                }}
                              >
                                <a
                                  href="#"
                                  className="accordion-head collapsed"
                                  data-toggle="collapse"
                                  data-target="#accordion-item-2-3"
                                >
                                  <h6 className="title">Voice Termination</h6>
                                  <span className="accordion-icon"></span>
                                </a>
                                <div
                                  className="accordion-body collapse"
                                  id="accordion-item-2-3"
                                  data-parent="#accordion-2"
                                >
                                  <div className="accordion-inner">
                                    <div className="col-md-12 col-lg-12 col-xxl-12">
                                      <MutextField
                                        id="term_service"
                                        name="term_service"
                                        select
                                        label="Select Termination Rate Service"
                                        value={this.state.term_service}
                                        onChange={this.handleChange}
                                        SelectProps={{
                                          native: true,
                                        }}
                                        helperText="Please select the termination rate."
                                        variant="outlined"
                                        fullWidth
                                      >
                                        <option value=""></option>
                                        {this.state.termArray.map(
                                          (termservices) => (
                                            <option
                                              value={
                                                termservices[0] +
                                                "###" +
                                                termservices[1]
                                              }
                                            >
                                              {termservices[1]}
                                            </option>
                                          )
                                        )}
                                      </MutextField>
                                    </div>
                                    <div className="col-md-12 col-lg-12 col-xxl-12">
                                      <div className="form-group">
                                        <MuiPickersUtilsProvider
                                          utils={DateFnsUtils}
                                        >
                                          <DatePicker
                                            id="term_service_date"
                                            name="term_service_date"
                                            format="yyyy-MM-dd"
                                            margin="normal"
                                            label="Select Publish Date"
                                            value={this.state.term_service_date}
                                            onChange={this.handleDateChangeTERM}
                                            keyboardbuttonprops={{
                                              "aria-label": "change date",
                                              variant: "outlined",
                                            }}
                                            fullWidth
                                            inputVariant="outlined"
                                          />
                                        </MuiPickersUtilsProvider>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {this.state.smsArray &&
                            this.state.smsArray.length > 0 ? (
                              <div
                                className="accordion-item"
                                style={{
                                  borderBottom: "1px solid #dbdfea",
                                  paddingBottom: "10px",
                                  paddingTop: "10px",
                                }}
                              >
                                <a
                                  href="#"
                                  className="accordion-head collapsed"
                                  data-toggle="collapse"
                                  data-target="#choose_sms_service"
                                >
                                  <h6 className="title">
                                    SMS Inbound / Outbound
                                  </h6>
                                  <span className="accordion-icon"></span>
                                </a>
                                <div
                                  className="accordion-body collapse"
                                  id="choose_sms_service"
                                  data-parent="#accordion-2"
                                >
                                  <div className="accordion-inner">
                                    <div className="col-md-12 col-lg-12 col-xxl-12">
                                      <MutextField
                                        id="sms_service"
                                        name="sms_service"
                                        select
                                        label="Select SMS Inbound / Outbound Service"
                                        value={this.state.sms_service}
                                        onChange={this.handleChange}
                                        SelectProps={{
                                          native: true,
                                        }}
                                        helperText="Please select the SMS Inbound / Outbound rate."
                                        variant="outlined"
                                        fullWidth
                                      >
                                        <option value=""></option>
                                        {this.state.smsArray.map(
                                          (termservices) => (
                                            <option
                                              value={
                                                termservices[0] +
                                                "###" +
                                                termservices[1]
                                              }
                                            >
                                              {termservices[1]}
                                            </option>
                                          )
                                        )}
                                      </MutextField>
                                    </div>
                                    <div className="col-md-12 col-lg-12 col-xxl-12">
                                      <div className="form-group">
                                        <MuiPickersUtilsProvider
                                          utils={DateFnsUtils}
                                        >
                                          <DatePicker
                                            id="sms_service_date"
                                            name="sms_service_date"
                                            format="yyyy-MM-dd"
                                            margin="normal"
                                            label="Select Publish Date"
                                            value={this.state.sms_service_date}
                                            onChange={this.handleDateChangeSMS}
                                            keyboardbuttonprops={{
                                              "aria-label": "change date",
                                              variant: "outlined",
                                            }}
                                            fullWidth
                                            inputVariant="outlined"
                                          />
                                        </MuiPickersUtilsProvider>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {this.state.teamsArray &&
                            this.state.teamsArray.length > 0 ? (
                              <div
                                className="accordion-item"
                                style={{
                                  borderBottom: "1px solid #dbdfea",
                                  paddingBottom: "10px",
                                  paddingTop: "10px",
                                }}
                              >
                                <a
                                  href="#"
                                  className="accordion-head collapsed"
                                  data-toggle="collapse"
                                  data-target="#choose_teams_service"
                                >
                                  <h6 className="title">
                                    SMS Inbound / Outbound
                                  </h6>
                                  <span className="accordion-icon"></span>
                                </a>
                                <div
                                  className="accordion-body collapse"
                                  id="choose_teams_service"
                                  data-parent="#accordion-2"
                                >
                                  <div className="accordion-inner">
                                    <div className="col-md-12 col-lg-12 col-xxl-12">
                                      <MutextField
                                        id="teams_service"
                                        name="teams_service"
                                        select
                                        label="Select Microsoft Teams UCAAS Service"
                                        value={this.state.teams_service}
                                        onChange={this.handleChange}
                                        SelectProps={{
                                          native: true,
                                        }}
                                        helperText="Please select the Microsoft Teams UCAAS rate."
                                        variant="outlined"
                                        fullWidth
                                      >
                                        <option value=""></option>
                                        {this.state.teamsArray.map(
                                          (termservices) => (
                                            <option
                                              value={
                                                termservices[0] +
                                                "###" +
                                                termservices[1]
                                              }
                                            >
                                              {termservices[1]}
                                            </option>
                                          )
                                        )}
                                      </MutextField>
                                    </div>
                                    <div className="col-md-12 col-lg-12 col-xxl-12">
                                      <div className="form-group">
                                        <MuiPickersUtilsProvider
                                          utils={DateFnsUtils}
                                        >
                                          <DatePicker
                                            id="teams_service_date"
                                            name="teams_service_date"
                                            format="yyyy-MM-dd"
                                            margin="normal"
                                            label="Select Publish Date"
                                            value={
                                              this.state.teams_service_date
                                            }
                                            onChange={
                                              this.handleDateChangeTEAMS
                                            }
                                            keyboardbuttonprops={{
                                              "aria-label": "change date",
                                            }}
                                            fullWidth
                                            inputVariant="outlined"
                                          />
                                        </MuiPickersUtilsProvider>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {this.state.tfnTermArray &&
                            this.state.tfnTermArray.length > 0 ? (
                              <div
                                className="accordion-item"
                                style={{
                                  borderBottom: "1px solid #dbdfea",
                                  paddingBottom: "10px",
                                  paddingTop: "10px",
                                }}
                              >
                                <a
                                  href="#"
                                  className="accordion-head collapsed"
                                  data-toggle="collapse"
                                  data-target="#choose_tfnterm_service"
                                >
                                  <h6 className="title">
                                    Toll Free Termination
                                  </h6>
                                  <span className="accordion-icon"></span>
                                </a>
                                <div
                                  className="accordion-body collapse"
                                  id="choose_tfnterm_service"
                                  data-parent="#accordion-2"
                                >
                                  <div className="accordion-inner">
                                    <div className="col-md-12 col-lg-12 col-xxl-12">
                                      <MutextField
                                        id="tfn_term_service"
                                        name="tfn_term_service"
                                        select
                                        label="Select Toll Free Termination Service"
                                        value={this.state.tfn_term_service}
                                        onChange={this.handleChange}
                                        SelectProps={{
                                          native: true,
                                        }}
                                        helperText="Please select the Toll Free Termination rate."
                                        variant="outlined"
                                        fullWidth
                                      >
                                        <option value=""></option>
                                        {this.state.tfnTermArray.map(
                                          (termservices) => (
                                            <option
                                              value={
                                                termservices[0] +
                                                "###" +
                                                termservices[1]
                                              }
                                            >
                                              {termservices[1]}
                                            </option>
                                          )
                                        )}
                                      </MutextField>
                                    </div>
                                    <div className="col-md-12 col-lg-12 col-xxl-12">
                                      <div className="form-group">
                                        <MuiPickersUtilsProvider
                                          utils={DateFnsUtils}
                                        >
                                          <DatePicker
                                            id="tfn_term_service_date"
                                            name="tfn_term_service_date"
                                            format="yyyy-MM-dd"
                                            margin="normal"
                                            label="Select Publish Date"
                                            value={
                                              this.state.tfn_term_service_date
                                            }
                                            onChange={
                                              this.handleDateChangeTfnTerm
                                            }
                                            keyboardbuttonprops={{
                                              "aria-label": "change date",
                                            }}
                                            fullWidth
                                            inputVariant="outlined"
                                          />
                                        </MuiPickersUtilsProvider>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {this.state.azTermArray &&
                            this.state.azTermArray.length > 0 ? (
                              <div
                                className="accordion-item"
                                style={{
                                  borderBottom: "1px solid #dbdfea",
                                  paddingBottom: "10px",
                                  paddingTop: "10px",
                                }}
                              >
                                <a
                                  href="#"
                                  className="accordion-head collapsed"
                                  data-toggle="collapse"
                                  data-target="#choose_azterm_service"
                                >
                                  <h6 className="title">
                                    International A-Z Termination
                                  </h6>
                                  <span className="accordion-icon"></span>
                                </a>
                                <div
                                  className="accordion-body collapse"
                                  id="choose_azterm_service"
                                  data-parent="#accordion-2"
                                >
                                  <div className="accordion-inner">
                                    <div className="col-md-12 col-lg-12 col-xxl-12">
                                      <MutextField
                                        id="az_term_service"
                                        name="az_term_service"
                                        select
                                        label="Select International A-Z Termination Service"
                                        value={this.state.az_term_service}
                                        onChange={this.handleChange}
                                        SelectProps={{
                                          native: true,
                                        }}
                                        helperText="Please select the International A-Z Termination rate."
                                        variant="outlined"
                                        fullWidth
                                      >
                                        <option value=""></option>
                                        {this.state.azTermArray.map(
                                          (termservices) => (
                                            <option
                                              value={
                                                termservices[0] +
                                                "###" +
                                                termservices[1]
                                              }
                                            >
                                              {termservices[1]}
                                            </option>
                                          )
                                        )}
                                      </MutextField>
                                    </div>
                                    <div className="col-md-12 col-lg-12 col-xxl-12">
                                      <div className="form-group">
                                        <MuiPickersUtilsProvider
                                          utils={DateFnsUtils}
                                        >
                                          <DatePicker
                                            id="az_term_service_date"
                                            name="az_term_service_date"
                                            format="yyyy-MM-dd"
                                            margin="normal"
                                            label="Select Publish Date"
                                            value={
                                              this.state.az_term_service_date
                                            }
                                            onChange={
                                              this.handleDateChangeAzTerm
                                            }
                                            keyboardbuttonprops={{
                                              "aria-label": "change date",
                                              variant: "outlined",
                                            }}
                                            fullWidth
                                            inputVariant="outlined"
                                          />
                                        </MuiPickersUtilsProvider>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {this.state.liveAgentArray &&
                            this.state.liveAgentArray.length > 0 ? (
                              <div
                                className="accordion-item"
                                style={{
                                  borderBottom: "1px solid #dbdfea",
                                  paddingBottom: "10px",
                                  paddingTop: "10px",
                                }}
                              >
                                <a
                                  href="#"
                                  className="accordion-head collapsed"
                                  data-toggle="collapse"
                                  data-target="#choose_la_service"
                                >
                                  <h6 className="title">Live Agent</h6>
                                  <span className="accordion-icon"></span>
                                </a>
                                <div
                                  className="accordion-body collapse"
                                  id="choose_la_service"
                                  data-parent="#accordion-2"
                                >
                                  <div className="accordion-inner">
                                    <div className="col-md-12 col-lg-12 col-xxl-12">
                                      <MutextField
                                        id="liveagent_service"
                                        name="liveagent_service"
                                        select
                                        label="Select Live Agent Service"
                                        value={this.state.liveagent_service}
                                        onChange={this.handleChange}
                                        SelectProps={{
                                          native: true,
                                        }}
                                        helperText="Please select the Live Agent rate."
                                        variant="outlined"
                                        fullWidth
                                      >
                                        <option value=""></option>
                                        {this.state.liveAgentArray.map(
                                          (termservices) => (
                                            <option
                                              value={
                                                termservices[0] +
                                                "###" +
                                                termservices[1]
                                              }
                                            >
                                              {termservices[1]}
                                            </option>
                                          )
                                        )}
                                      </MutextField>
                                    </div>
                                    <div className="col-md-12 col-lg-12 col-xxl-12">
                                      <div className="form-group">
                                        <MuiPickersUtilsProvider
                                          utils={DateFnsUtils}
                                        >
                                          <DatePicker
                                            id="liveagent_service_date"
                                            name="liveagent_service_date"
                                            format="yyyy-MM-dd"
                                            margin="normal"
                                            label="Select Publish Date"
                                            value={
                                              this.state.liveagent_service_date
                                            }
                                            onChange={this.handleDateChangeLA}
                                            keyboardbuttonprops={{
                                              "aria-label": "change date",
                                              variant: "outlined",
                                            }}
                                            fullWidth
                                            inputVariant="outlined"
                                          />
                                        </MuiPickersUtilsProvider>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}

                            {this.state.cnamArray &&
                            this.state.cnamArray.length > 0 ? (
                              <div
                                className="accordion-item"
                                style={{
                                  borderBottom: "1px solid #dbdfea",
                                  paddingBottom: "10px",
                                  paddingTop: "10px",
                                }}
                              >
                                <a
                                  href="#"
                                  className="accordion-head collapsed"
                                  data-toggle="collapse"
                                  data-target="#choose_cnam_service"
                                >
                                  <h6 className="title">CNAM</h6>
                                  <span className="accordion-icon"></span>
                                </a>
                                <div
                                  className="accordion-body collapse"
                                  id="choose_cnam_service"
                                  data-parent="#accordion-2"
                                >
                                  <div className="accordion-inner">
                                    <div className="col-md-12 col-lg-12 col-xxl-12">
                                      <MutextField
                                        id="cnam_service"
                                        name="cnam_service"
                                        select
                                        label="Select CNAM Service"
                                        value={this.state.cnam_service}
                                        onChange={this.handleChange}
                                        SelectProps={{
                                          native: true,
                                        }}
                                        helperText="Please select the CNAM rate."
                                        variant="outlined"
                                        fullWidth
                                      >
                                        <option value=""></option>
                                        {this.state.cnamArray.map(
                                          (termservices) => (
                                            <option
                                              value={
                                                termservices[0] +
                                                "###" +
                                                termservices[1]
                                              }
                                            >
                                              {termservices[1]}
                                            </option>
                                          )
                                        )}
                                      </MutextField>
                                    </div>
                                    <div className="col-md-12 col-lg-12 col-xxl-12">
                                      <div className="form-group">
                                        <MuiPickersUtilsProvider
                                          utils={DateFnsUtils}
                                        >
                                          <DatePicker
                                            id="cnam_service_date"
                                            name="cnam_service_date"
                                            format="yyyy-MM-dd"
                                            margin="normal"
                                            label="Select Publish Date"
                                            value={this.state.cnam_service_date}
                                            onChange={this.handleDateChangeCnam}
                                            keyboardbuttonprops={{
                                              "aria-label": "change date",
                                              variant: "outlined",
                                            }}
                                            fullWidth
                                            inputVariant="outlined"
                                          />
                                        </MuiPickersUtilsProvider>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>

                          <div className="nk-modal">
                            <div className="nk-modal-action  text-right">
                              {this.state.emptyService == 0 ? (
                                <a
                                  style={{ cursor: "pointer", color: "#fff" }}
                                  className="btn btn-lg btn-mw btn-primary "
                                  onClick={() => {
                                    this.formSave();
                                  }}
                                  disabled={this.state.disabled}
                                >
                                  Assign Services
                                </a>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="selectPackage">
                          {this.state.bundlesQueue &&
                          this.state.bundlesQueue.length > 0 ? (
                            <div
                              class="example-alert"
                              style={{ marginBottom: "20px" }}
                            >
                              <div class="alert alert-warning alert-icon">
                                <em class="icon ni ni-alert-circle"></em>{" "}
                                <strong>Alert: </strong>This client already have
                                a request to assign packages in our queue. If
                                you assign the packages again, previous request
                                will be discarded.{" "}
                              </div>
                            </div>
                          ) : null}
                          {this.state.bundleData &&
                          this.state.bundleData.length > 0 ? (
                            <>
                              <div className="col-lg-12 col-md-12">
                                <div className="form-group select_services">
                                  <label className="form-label">
                                    Select Packages
                                  </label>
                                  <ul className="custom-control-group custom-control-vertical custom-control-stacked w-100">
                                    {this.state.bundleData.map(
                                      (splits, index) => (
                                        <li key={`addser${index}`}>
                                          <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                                            <input
                                              type="radio"
                                              className="custom-control-input vdrSelected"
                                              id={`cp1-package-${index}`}
                                              name="bundle_service"
                                              defaultChecked={false}
                                              value={
                                                splits.id +
                                                "###" +
                                                splits.bundle_title
                                              }
                                              onChange={this.handleChange}
                                              ref="bundle_service"
                                              required=""
                                            />
                                            <label
                                              className="custom-control-label"
                                              htmlFor={`cp1-package-${index}`}
                                            >
                                              <span className="d-flex align-center">
                                                <span className="user-avatar sq bg-primary-dim">
                                                  <span className="icon ni ni-briefcase"></span>
                                                </span>
                                                <span className="ms-2">
                                                  <span className="lead-text">
                                                    {splits.bundle_title}
                                                  </span>
                                                  <span className="sub-text">
                                                    Per Month Rate (Charge
                                                    Monthly): $
                                                    {splits.per_month_rate
                                                      ? splits.per_month_rate.toFixed(
                                                          4
                                                        )
                                                      : "0.00"}
                                                    <br />
                                                    Per Month Rate (Charge
                                                    Yearly): $
                                                    {splits.per_year_rate
                                                      ? splits.per_year_rate.toFixed(
                                                          4
                                                        )
                                                      : "0.00"}
                                                  </span>
                                                </span>
                                              </span>
                                            </label>
                                          </div>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </div>
                              <div
                                className="col-md-12 col-lg-12 col-xxl-12"
                                style={{
                                  marginTop: "15px",
                                  marginBottom: "15px",
                                }}
                              >
                                <div className="form-group">
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                      id="bundle_date"
                                      name="bundle_date"
                                      format="yyyy-MM-dd"
                                      margin="normal"
                                      label="Select Publish Date"
                                      value={this.state.bundle_date}
                                      onChange={this.handleDateChangeBundle}
                                      keyboardbuttonprops={{
                                        "aria-label": "change date",
                                        variant: "outlined",
                                      }}
                                      fullWidth
                                      inputVariant="outlined"
                                    />
                                  </MuiPickersUtilsProvider>
                                </div>
                              </div>
                              <div
                                className="form-group text-right col-md-12"
                                style={{ marginTop: "20px" }}
                              >
                                <button
                                  className="btn btn-lg btn-primary"
                                  type="button"
                                  disabled={this.state.disabled}
                                  onClick={() => {
                                    this.assignBundles();
                                  }}
                                >
                                  Assign Bundle
                                </button>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* DETAIL MODAL End */}
              {/* DETAIL MODAL START */}
              <div
                className="modal fade"
                tabIndex="-1"
                id="modalAssignedServices"
              >
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em className="icon ni ni-file-docs"></em> Assigned
                          Products of{" "}
                          <sup>
                            <small className="text-soft">
                              {this.state.company_title}
                            </small>
                          </sup>
                        </strong>
                      </div>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      <div className="tab-content">
                        <div className="tab-pane active" id="assigned_services">
                          <div className="col-lg-12">
                            {this.state.assignedErrorMessage !== "" ? (
                              <div class="alert alert-pro alert-danger">
                                <div class="alert-text">
                                  <h6>Error</h6>
                                  <p>{this.state.assignedErrorMessage}</p>
                                </div>
                              </div>
                            ) : null}
                            {this.state.assignedSuccessMessage !== "" ? (
                              <div class="alert alert-pro alert-success">
                                <div class="alert-text">
                                  <h6>Success</h6>
                                  <p>{this.state.assignedSuccessMessage}</p>
                                </div>
                              </div>
                            ) : null}
                            {this.state.showServiceLoader === true ? (
                              FormLoader()
                            ) : (
                              <div
                                className="card card-bordered genericTable"
                                id="assignedServicesTable"
                              >
                                {this.state.serviceAssignedData &&
                                this.state.serviceAssignedData.length > 0 ? (
                                  <>
                                    <table className="table table-striped table-bordered">
                                      <thead>
                                        <tr>
                                          <th
                                            className="text-left"
                                            style={{ paddingLeft: "1.25rem" }}
                                          >
                                            <div className="custom-control custom-checkbox">
                                              <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="product_ids"
                                                checked={this.state.checkAll}
                                                onChange={
                                                  this.handleCheckAllChange
                                                }
                                              />
                                              <label
                                                className="custom-control-label"
                                                htmlFor="product_ids"
                                              ></label>
                                            </div>
                                          </th>
                                          <th scope="col">Title</th>
                                          <th scope="col">Price</th>
                                          <th scope="col">SKU</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.serviceAssignedData.map(
                                          (service, index) => (
                                            <tr key={`ser${index}`}>
                                              <td>
                                                <div className="custom-control custom-checkbox">
                                                  <input
                                                    type="checkbox"
                                                    checked={service.checked}
                                                    class="custom-control-input"
                                                    name="selected_products"
                                                    onChange={() =>
                                                      this.handleCheckboxChange(
                                                        service.id
                                                      )
                                                    }
                                                    value={service.id}
                                                    id={`product_id_${index}`}
                                                  />
                                                  <label
                                                    className="custom-control-label"
                                                    htmlFor={`product_id_${index}`}
                                                  ></label>
                                                </div>
                                              </td>
                                              <td>{service.service_title}</td>

                                              <td>
                                                $
                                                {service.rate &&
                                                service.rate > 0
                                                  ? service.rate.toFixed(2)
                                                  : "0.00"}
                                              </td>
                                              <td>{service.sku}</td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                    <div className="col-sm-12 mt-4 mb-2 d-flex justify-content-end">
                                      <div class="nk-block-head-content">
                                        <ul class="nk-block-tools gx-3">
                                          {this.state.update_service_button ===
                                          true ? (
                                            <button
                                              className="btn btn-primary"
                                              type="button"
                                              disabled
                                            >
                                              <span
                                                className="spinner-grow spinner-grow-sm"
                                                role="status"
                                                aria-hidden="true"
                                              ></span>
                                              <span> Processing... </span>
                                            </button>
                                          ) : (
                                            <li>
                                              <a
                                                onClick={() =>
                                                  this.updateAssignedServices()
                                                }
                                                style={{
                                                  cursor: "pointer",
                                                  color: "#fff",
                                                }}
                                                class="btn btn-primary"
                                              >
                                                <em class="icon ni ni-check-round-cut"></em>{" "}
                                                <span>Update</span>{" "}
                                              </a>
                                            </li>
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h6>No Assigned Service</h6>
                                      <p>
                                        Services are not assigned to this
                                        client.{" "}
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="tab-pane" id="assigned_packages">
                          <div className="col-lg-12">
                            {this.state.showServiceLoader === true ? (
                              FormLoader()
                            ) : (
                              <div
                                className="card card-bordered genericTable"
                                id="assignedPackagesTable"
                              >
                                {this.state.packagesAssignedData &&
                                this.state.packagesAssignedData.length > 0 ? (
                                  <table className="table table-striped table-bordered">
                                    <thead>
                                      <tr>
                                        <th scope="col">Title</th>
                                        <th scope="col">
                                          Rate Per Month (Charge Monthly)
                                        </th>
                                        <th scope="col">
                                          Rate Per Month (Charge Yearly)
                                        </th>
                                        <th scope="col">Date Added</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.packagesAssignedData.map(
                                        (packages, index) => (
                                          <tr key={`ser${index}`}>
                                            <th scope="row">
                                              {packages.bundle_title}
                                            </th>
                                            <td>
                                              $
                                              {packages.per_month_rate &&
                                              packages.per_month_rate !== "0"
                                                ? packages.per_month_rate.toFixed(
                                                    2
                                                  )
                                                : "0.00"}
                                            </td>
                                            <td>
                                              $
                                              {packages.per_year_rate &&
                                              packages.per_year_rate !== "0"
                                                ? packages.per_year_rate.toFixed(
                                                    2
                                                  )
                                                : "0.00"}
                                            </td>
                                            <td>
                                              {packages.date_added &&
                                              packages.date_added !== ""
                                                ? this.format_date(
                                                    packages.date_added
                                                  )
                                                : null}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                ) : (
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h6>No Assigned Package</h6>
                                      <p>
                                        Packages are not assigned to this
                                        client.{" "}
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* DETAIL MODAL End */}
              {/* DETAIL MODAL START */}
              <div className="modal fade" tabIndex="-1" id="modalClientDetails">
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em className="icon ni ni-card-view"></em> Extra
                          Details of{" "}
                          <sup>
                            <small className="text-soft">
                              {this.state.company_title}
                            </small>
                          </sup>
                        </strong>
                      </div>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      {this.state.kycErrorMessage !== "" ? (
                        <div
                          className="example-alert example-alert-revoke"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.kycErrorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.kycSuccessMessage !== "" ? (
                        <div
                          className="example-alert example-alert-revoke"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.kycSuccessMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.kyc_key === "" ? (
                        <div className="nk-block">
                          <div className="card card-bordered">
                            <div className="card-inner card-inner-lg">
                              <div className="nk-help">
                                <div className="nk-help-img">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 90 90"
                                  >
                                    <rect
                                      x="5"
                                      y="7"
                                      width="60"
                                      height="56"
                                      rx="7"
                                      ry="7"
                                      fill="#e3e7fe"
                                      stroke="#6576ff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></rect>
                                    <rect
                                      x="25"
                                      y="27"
                                      width="60"
                                      height="56"
                                      rx="7"
                                      ry="7"
                                      fill="#e3e7fe"
                                      stroke="#6576ff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></rect>
                                    <rect
                                      x="15"
                                      y="17"
                                      width="60"
                                      height="56"
                                      rx="7"
                                      ry="7"
                                      fill="#fff"
                                      stroke="#6576ff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></rect>
                                    <line
                                      x1="15"
                                      y1="29"
                                      x2="75"
                                      y2="29"
                                      fill="none"
                                      stroke="#6576ff"
                                      strokeMiterlimit="10"
                                      strokeWidth="2"
                                    ></line>
                                    <circle
                                      cx="53"
                                      cy="23"
                                      r="2"
                                      fill="#c4cefe"
                                    ></circle>
                                    <circle
                                      cx="60"
                                      cy="23"
                                      r="2"
                                      fill="#c4cefe"
                                    ></circle>
                                    <circle
                                      cx="67"
                                      cy="23"
                                      r="2"
                                      fill="#c4cefe"
                                    ></circle>
                                    <rect
                                      x="22"
                                      y="39"
                                      width="20"
                                      height="20"
                                      rx="2"
                                      ry="2"
                                      fill="none"
                                      stroke="#6576ff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></rect>
                                    <circle
                                      cx="32"
                                      cy="45.81"
                                      r="2"
                                      fill="none"
                                      stroke="#6576ff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></circle>
                                    <path
                                      d="M29,54.31a3,3,0,0,1,6,0"
                                      fill="none"
                                      stroke="#6576ff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></path>
                                    <line
                                      x1="49"
                                      y1="40"
                                      x2="69"
                                      y2="40"
                                      fill="none"
                                      stroke="#6576ff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></line>
                                    <line
                                      x1="49"
                                      y1="51"
                                      x2="69"
                                      y2="51"
                                      fill="none"
                                      stroke="#c4cefe"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></line>
                                    <line
                                      x1="49"
                                      y1="57"
                                      x2="59"
                                      y2="57"
                                      fill="none"
                                      stroke="#c4cefe"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></line>
                                    <line
                                      x1="64"
                                      y1="57"
                                      x2="66"
                                      y2="57"
                                      fill="none"
                                      stroke="#c4cefe"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></line>
                                    <line
                                      x1="49"
                                      y1="46"
                                      x2="59"
                                      y2="46"
                                      fill="none"
                                      stroke="#c4cefe"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></line>
                                    <line
                                      x1="64"
                                      y1="46"
                                      x2="66"
                                      y2="46"
                                      fill="none"
                                      stroke="#c4cefe"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    ></line>
                                  </svg>
                                </div>
                                <div className="nk-help-text">
                                  <h5>Initiate KYC Process</h5>
                                  <p className="text-soft">
                                    Register your customer with KYC to complete
                                    their profile. Click on "Initiate KYC"
                                    button. An email with KYC registration link
                                    will be sent to the customer.
                                  </p>
                                </div>
                                <div className="nk-help-action">
                                  {this.state.loading_button === false ? (
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        this.initiateKyc();
                                      }}
                                      disabled={this.state.disabled}
                                      href="#"
                                      className="btn btn-lg btn-outline-primary"
                                    >
                                      <em className="icon ni ni-file-docs"></em>{" "}
                                      Initiate KYC
                                    </a>
                                  ) : null}
                                  {this.state.loading_button === true ? (
                                    <button
                                      className="btn btn-primary btn-lg"
                                      type="button"
                                      disabled
                                    >
                                      <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                      <span> Initiating... </span>
                                    </button>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.kyc_key !== "" ? (
                        <div id="printablediv">
                          <div className="nk-content-body">
                            <div className="nk-block-head nk-block-head-sm">
                              <div className="nk-block-between g-3">
                                <div className="nk-block-head-content">
                                  <h3 className="nk-block-title page-title">
                                    KYCs /{" "}
                                    <strong className="text-primary small">
                                      {this.state.company_title}
                                    </strong>
                                  </h3>
                                  <div className="nk-block-des text-soft">
                                    <ul className="list-inline">
                                      {/*<li>
                                        Application ID:{" "}
                                        <span className="text-base">
                                          KID000844
                                        </span>
                      </li>*/}
                                      <li>
                                        Submited At:{" "}
                                        <span className="text-base">
                                          {this.state.client_register_date}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="nk-block-head-content">
                                  <div className="btn-group">
                                    {this.state.completed_percentage < 100 ? (
                                      <button
                                        onClick={() => {
                                          this.email_kyc_Details();
                                        }}
                                        type="button"
                                        className="btn btn-outline-primary"
                                        disabled={this.state.disabled}
                                      >
                                        <em className="icon ni ni-emails"></em>
                                        <span>Send Email</span>
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        onClick={() => {
                                          this.downloadPdf(this.state.kyc_key);
                                        }}
                                        className="btn btn-outline-primary"
                                      >
                                        <em className="icon ni ni-download"></em>
                                        <span>Download PDF</span>
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="nk-block-head">
                              <div className="nk-block-head-content">
                                <h5 className="nk-block-title title">
                                  Profile Completed{" "}
                                  <span className="text-primary">
                                    ({this.state.completed_percentage}%)
                                  </span>
                                </h5>
                                <div className="card card-bordered card-preview">
                                  <div className="card-inner">
                                    <div className="progress progress-lg">
                                      <div
                                        className="progress-bar"
                                        data-progress={
                                          this.state.completed_percentage
                                        }
                                        style={{
                                          width: `${this.state.completed_percentage}%`,
                                        }}
                                      >
                                        {this.state.completed_percentage}%
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {this.state.kyc_details &&
                          this.state.kyc_details.length > 0 ? (
                            <div id="accordion" className="accordion">
                              {this.state.kyc_details.map((kyc, i) => (
                                <div
                                  className="accordion-item"
                                  key={`kycid${i}`}
                                >
                                  <a
                                    href="#"
                                    className={this.handle_accordion_head(i)}
                                    data-toggle="collapse"
                                    data-target={`#accordion-item-${i}`}
                                  >
                                    <h6 className="title">
                                      {kyc.steps_information.step_title}
                                      <br />
                                      <small>
                                        {kyc.steps_information.step_description}
                                      </small>
                                    </h6>
                                    <span className="accordion-icon"></span>
                                  </a>
                                  <div
                                    className={this.handle_accordion_body(i)}
                                    id={`accordion-item-${i}`}
                                    data-parent="#accordion"
                                  >
                                    <div className="accordion-inner">
                                      {kyc.steps_data &&
                                      kyc.steps_data.length > 0 ? (
                                        <div className="card">
                                          <ul className="data-list is-compact">
                                            {kyc.steps_data.map(
                                              (kyc_steps, i) => (
                                                <li
                                                  className="data-item"
                                                  key={`step${i}`}
                                                >
                                                  <div className="data-col">
                                                    <div className="data-label">
                                                      {kyc_steps.field_title}
                                                    </div>
                                                    <div className="data-value">
                                                      {kyc_steps.field_type ===
                                                      "image" ? (
                                                        <img
                                                          src={
                                                            kyc_steps.field_value
                                                          }
                                                          style={{
                                                            width: "60%",
                                                          }}
                                                        />
                                                      ) : (
                                                        kyc_steps.field_value
                                                      )}
                                                    </div>
                                                  </div>
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : null}
                        </div>
                      ) : null}

                      <div className="col-lg-12">&nbsp;</div>
                    </div>
                  </div>
                </div>
              </div>
              {/* DETAIL MODAL End */}
              {/* CREDIT APPLICATIONS MODAL START */}
              <div
                className="modal fade"
                tabindex="-1"
                id="modalCreditApplications"
              >
                <div
                  className="modal-dialog modal-dialog-top modal-xl"
                  role="document"
                >
                  <div className="modal-content">
                    <a
                      href="#"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-header">
                      <h5 className="modal-title">
                        <em className="icon ni ni-coin"></em> Credit
                        Applications
                      </h5>
                    </div>
                    <div className="modal-body">
                      {this.state.creditErrorMessage !== "" ? (
                        <div
                          className="example-alert example-alert-revoke"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.creditErrorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.creditSuccessMessage !== "" ? (
                        <div
                          className="example-alert example-alert-revoke"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.creditSuccessMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.processing_request === true ? (
                        <div className="example-alert mb-3">
                          <div className="alert alert-light">
                            <div
                              className="spinner-grow spinner-grow-sm"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>{" "}
                            Please wait while we are processing your request.
                          </div>
                        </div>
                      ) : null}

                      <div
                        className="card card-bordered"
                        id="creditApplicationTable"
                      >
                        {this.state.credit_applications &&
                        this.state.credit_applications.length > 0 ? (
                          <table className="table table-striped table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">ID</th>
                                <th scope="col">Created Date</th>
                                <th scope="col">Approved date</th>
                                <th scope="col">Rejected Date</th>
                                <th scope="col">Status</th>
                                <th scope="col">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.credit_applications.map(
                                (applications, index) => (
                                  <tr key={`cr${index}`}>
                                    <th scope="row">{applications[0]}</th>
                                    <td>{this.format_date(applications[1])}</td>
                                    <td>
                                      {applications[2] && applications[2] !== ""
                                        ? this.format_date(applications[2])
                                        : "---"}
                                    </td>
                                    <td>
                                      {applications[3] && applications[3] !== ""
                                        ? this.format_date(applications[3])
                                        : "---"}
                                    </td>
                                    <td>
                                      {applications[4] === "pending" ? (
                                        <span className="badge badge-dot badge-warning">
                                          Pending
                                        </span>
                                      ) : applications[4] === "approved" ? (
                                        <span className="badge badge-dot badge-success">
                                          Approved
                                        </span>
                                      ) : applications[4] === "rejected" ? (
                                        <span className="badge badge-dot badge-danger">
                                          Rejected
                                        </span>
                                      ) : null}
                                    </td>
                                    <td>
                                      <div className="dropdown">
                                        <a
                                          className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                          data-toggle="dropdown"
                                        >
                                          <em className="icon ni ni-more-h"></em>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-right dropdown-menu-xs">
                                          <ul className="link-list-plain">
                                            <li>
                                              <a
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  this.downloadCreditAppPdf(
                                                    applications[6]
                                                  )
                                                }
                                              >
                                                View PDF
                                              </a>
                                            </li>
                                            {applications[4] === "pending" ? (
                                              <li>
                                                <a
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() => {
                                                    this.setState({
                                                      application_no:
                                                        applications[0],
                                                      rejection_container: true,
                                                    });
                                                  }}
                                                >
                                                  Reject
                                                </a>
                                              </li>
                                            ) : null}
                                            {applications[4] === "pending" ? (
                                              <li>
                                                <a
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() =>
                                                    this.approveCreditApplication(
                                                      applications[0]
                                                    )
                                                  }
                                                >
                                                  Approve
                                                </a>
                                              </li>
                                            ) : null}
                                          </ul>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        ) : (
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h6>No Credit Application</h6>
                              <p>
                                Credit applications are not available for this
                                client at the moment.{" "}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="modal-footer text-right d-flex justify-content-end">
                      <a
                        href="#"
                        className="btn btn-lg btn-mw btn-light"
                        data-dismiss="modal"
                      >
                        Close
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/* CREDIT APPLICATIONS MODAL END */}

              {/* Client Agreements START */}
              <div
                className="modal fade"
                tabindex="-1"
                id="modalClientAgreements"
              >
                <div
                  className="modal-dialog modal-dialog-top modal-xl"
                  role="document"
                >
                  <div className="modal-content">
                    <a
                      href="#"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-header">
                      <h5 className="modal-title">
                        <em className="icon ni ni-file-text"></em> Client
                        Agreements
                      </h5>
                    </div>
                    <div className="modal-body">
                      <div className="card card-bordered" id="">
                        {this.state.agreementListing &&
                        this.state.agreementListing.length > 0 ? (
                          <table className="table table-striped table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">Agreement Name</th>
                                <th scope="col">Signed Date</th>
                                <th scope="col">Approved</th>
                                <th scope="col">Status</th>
                                <th scope="col">Download</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.agreementListing.map(
                                (applications, index) => (
                                  <tr key={`cr${index}`}>
                                    <th scope="row">
                                      {applications.agreement_title}
                                    </th>
                                    <td>
                                      {this.format_date(
                                        applications.signed_date
                                      )}
                                    </td>
                                    <td>
                                      {applications.approved === "Yes" ? (
                                        <span className="badge badge-dot badge-success">
                                          Yes
                                        </span>
                                      ) : applications.approved === "No" ? (
                                        <span className="badge badge-dot badge-danger">
                                          No
                                        </span>
                                      ) : null}
                                    </td>
                                    <td>
                                      {applications.is_signed === "Yes" ? (
                                        <span className="badge badge-dot badge-success">
                                          Signed
                                        </span>
                                      ) : applications.is_signed === "No" ? (
                                        <span className="badge badge-dot badge-danger">
                                          Unsigned
                                        </span>
                                      ) : null}
                                    </td>
                                    <td>
                                      {applications.pdf_path &&
                                      applications.pdf_path !== "" ? (
                                        <a
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            this.downloadAgreement(
                                              applications.pdf_path,
                                              applications.agreement_title
                                            )
                                          }
                                        >
                                          <span class="badge badge-outline-primary">
                                            <em class="icon ni ni-download"></em>
                                          </span>
                                        </a>
                                      ) : (
                                        <span class="badge badge-outline-light">
                                          ---
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        ) : (
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h6>No Client Agreements</h6>
                              <p>
                                Agreements are not available for this client at
                                the moment.{" "}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="modal-footer text-right d-flex justify-content-end">
                      <a
                        href="#"
                        className="btn btn-lg btn-mw btn-light"
                        data-dismiss="modal"
                      >
                        Close
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/* Client Agreements */}
              <div className="nk-block">
              <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="row g-gs" style={{ clear: "both" }}>
                      <div className="col-lg-12">
                        <div
                          className="card card-bordered"
                          style={{ clear: "both" }}
                        >
                          <div className="card-inner">
                            <ul class="nav nav-tabs">
                              <li class="nav-item">
                                <a
                                  class={`nav-link ${this.state.activeTabCP === "tabItem7"
                                    ? "active"
                                    : ""
                                    }`}
                                  data-toggle="tab"
                                  href="#tabItem7"
                                  onClick={() =>
                                    this.handleTabClick("tabItem7")
                                  }
                                >
                                  <em class="icon ni ni-user-list"></em>
                                  <span>All Clients</span>
                                </a>
                              </li>
                              <li class="nav-item">
                                <a
                                  class={`nav-link ${this.state.activeTabCP === "tabItem8"
                                    ? "active"
                                    : ""
                                    }`}
                                  data-toggle="tab"
                                  href="#tabItem8"
                                  onClick={() =>
                                    this.handleTabClick("tabItem8")
                                  }
                                >
                                  <em class="icon ni ni-users-list"></em>
                                  <span>Pending Invitations</span>
                                </a>
                              </li>
                            </ul>
                            <div class="tab-content" style={{ marginTop: 0 }}>
                              <div
                                class={`tab-pane ${this.state.activeTabCP === "tabItem7"
                                  ? "active"
                                  : ""
                                  }`}
                                id="tabItem7"
                              >
                                <div className="row">
                                  <div className="col-md-12">
                                    {this.state.tableLoader === true ? (
                                      tableLoader()
                                    ) : (
                                      <DataTable
                                        columns={this.state.columnServices}
                                        tableData={this.state.tableData}
                                        title="All Clients"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div
                                class={`tab-pane ${this.state.activeTabCP === "tabItem8"
                                  ? "active"
                                  : ""
                                  }`}
                                id="tabItem8"
                              >
                                <div className="row">
                                  <div className="col-md-12">
                                    {this.state.tableLoader === true ? (
                                      tableLoader()
                                    ) : (
                                      <DataTable
                                        columns={this.state.columnServices_invitedClients}
                                        tableData={this.state.tableData_invitedClients}
                                        title="Pending Invitations"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                </div>
                {/*<div className="row g-gs">
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      {this.state.tableLoader === true ? (
                        tableLoader()
                      ) : (
                        <DataTable
                          columns={this.state.columnServices}
                          tableData={this.state.tableData}
                          title="All Clients"
                        />
                      )}
                    </div>
                  </div>
                </div>*/}

              </div>
            </div>
          </div>
          {/* Client Update Modal Start */}
          <div className="modal fade" tabIndex="-1" id="modalUpdateClient">
            <div
              className="modal-dialog modal-xl modal-dialog-top"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Edit Client Profile</h5>
                  <a
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <em className="icon ni ni-cross"></em>
                  </a>
                </div>

                <div className="modal-body">
                  {/* Error Start */}
                  {this.state.errorClientMessageEdit !== "" ? (
                    <div
                      className="example-alert"
                      style={{ marginBottom: "20px" }}
                    >
                      <div className="alert alert-pro alert-danger">
                        <div className="alert-text">
                          <h4>Error</h4>

                          <p
                            dangerouslySetInnerHTML={{
                              __html: this.state.errorClientMessageEdit,
                            }}
                          ></p>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {this.state.successClientMessageEdit !== "" ? (
                    <div
                      className="example-alert"
                      style={{ marginBottom: "20px" }}
                    >
                      <div className="alert alert-pro alert-success">
                        <div className="alert-text">
                          <h4>Success</h4>
                          <p>{this.state.successClientMessageEdit}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <br />

                  <div className="row g-4">
                    {this.state.filer_id && this.state.filer_id != "" && (
                      <div className="col-lg-4">
                        <div className="form-group">
                          <TextField
                            label="499 Filer ID"
                            error={this.state.errorClientCompName}
                            id="filer_id"
                            name="filer_id"
                            value={this.state.filer_id}
                            placeholder="499 Filer ID"
                            change={this.handleChange}
                            type="text"
                            disabled={true}
                            maxLength={100}
                          />
                        </div>
                      </div>
                    )}
                    <div className="col-lg-4">
                      <div className="form-group">
                        <TextField
                          label="Company Name"
                          error={this.state.errorClientCompName}
                          id="client_comp_name"
                          name="client_comp_name"
                          value={this.state.client_comp_name}
                          placeholder="Company Name"
                          change={this.handleChange}
                          type="text"
                          maxLength={100}
                        />
                        <small
                          className="form-text text-dark"
                          style={{
                            position: "absolute",
                            top: "80px",
                            color: "#555",
                            fontSize: "0.75rem",
                            fontWeight: "400",
                            fontFamily:
                              '"Roboto", "Helvetica", "Arial", sans-serif',
                          }}
                        >
                          Allowed characters: (&nbsp; _ &nbsp; . &nbsp; - &nbsp;
                          , &nbsp; &amp; &nbsp; ( ) )
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <TextField
                          label="Full Name"
                          error={this.state.errorClientName}
                          id="client_update_name"
                          name="client_update_name"
                          value={this.state.client_update_name}
                          placeholder="Full Name"
                          change={this.handleChange}
                          maxLength={100}
                        />
                        <small
                          className="form-text text-dark"
                          style={{
                            position: "absolute",
                            top: "80px",
                            color: "#555",
                            fontSize: "0.75rem",
                            fontWeight: "400",
                            fontFamily:
                              '"Roboto", "Helvetica", "Arial", sans-serif',
                          }}
                        >
                          Allowed characters: (&nbsp; ' &nbsp; - &nbsp; . &nbsp;
                          , )
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <TextField
                          label="Title"
                          error={this.state.errorClientTitle}
                          id="client_title"
                          name="client_title"
                          value={this.state.client_title}
                          placeholder="Title"
                          change={this.handleChange}
                          maxLength={30}
                        />

                        <small
                          className="form-text text-dark"
                          style={{
                            position: "absolute",
                            top: "80px",
                            color: "#555",
                            fontSize: "0.75rem",
                            fontWeight: "400",
                            fontFamily:
                              '"Roboto", "Helvetica", "Arial", sans-serif',
                          }}
                        >
                          Allowed characters: (&nbsp; _ &nbsp; - &nbsp; . &nbsp;
                          &amp; &nbsp; , &nbsp; ( ) )
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <TextField
                          label="Email"
                          type="text"
                          error={this.state.errorClientEmail}
                          id="client_update_email"
                          name="client_update_email"
                          value={this.state.client_update_email}
                          placeholder="Enter email address"
                          change={this.handleChange}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <PhoneInput
                        style={{ height: "60%", marginTop: "30px" }}
                        required
                        defaultCountry={"us"}
                        error={this.state.errorPhone}
                        inputProps={{
                          name: "client_phone",
                          id: "client_phone",
                          maxLength: 20,
                          className: this.state.errorClientPhone
                            ? "error-border"
                            : "default-border",
                        }}
                        dialCodePreviewStyleProps={{
                          className: this.state.errorClientPhone
                            ? "error-border"
                            : "default-border",
                        }}
                        value={this.state.client_phone}
                        onChange={(value) =>
                          this.handleChange({
                            target: { name: "client_phone", value },
                          })
                        }
                        helperText="Enter the client phone number."
                        label="Client Phone Number"
                        ref={this.phoneInputRef}
                        onFocus={this.handleFocus}
                      />
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <div class="nk-block-between-md">
                          <div class="nk-block-head-content">
                            <label
                              htmlFor="googleLocation-autocomplete"
                              style={{
                                color: "black",
                                fontWeight: "500",
                                marginBottom: "8px",
                              }}
                            >
                              Address
                            </label>
                          </div>
                          <div class="nk-block-head-content">
                            <ul class="nk-block-tools gx-3">
                              <li>
                                <a
                                  onClick={this.handleToggle}
                                  class="text-primary fs-11"
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "11px",
                                  }}
                                >
                                  {this.state.toggleButtonText}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {this.state.useGooglePlaces ? (
                          <GooglePlacesAutocomplete
                            apiKey={
                              process.env.REACT_APP_ADDRESS_AUTOCOMPLETE_API_KEY
                            }
                            selectProps={{
                              value: this.state.client_address
                                ? {
                                    label: this.state.client_address,
                                    value: this.state.client_address,
                                  }
                                : null,
                              onChange: this.handleAddressSelect,
                              placeholder: this.state.client_address
                                ? null
                                : "Enter your address..",
                              styles: {
                                control: (provided) => ({
                                  ...provided,
                                  minHeight: "43px",
                                }),
                              },
                            }}
                            className="googleLocation-autocomplete"
                          />
                        ) : (
                          <input
                            type="text"
                            className="form-control"
                            id="client_address"
                            name="client_address"
                            value={this.state.client_address}
                            placeholder="Enter your PO Box Address"
                            onChange={this.handleChange}
                            style={{
                              borderColor: this.state.errorAddress
                                ? "red"
                                : "initial",
                              height: "45px",
                              border: "1px solid #dbdfea",
                              fontSize: "0.9375rem",
                            }}
                          />
                        )}
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="form-group">
                        <TextField
                          label="City"
                          type="text"
                          error={this.state.errorClientCity}
                          id="client_city"
                          name="client_city"
                          value={this.state.client_city}
                          placeholder="Enter city"
                          change={this.handleChange}
                          maxLength={32}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <TextField
                          label="Appartment, Suite etc"
                          type="text"
                          id="client_address2"
                          name="client_address2"
                          value={this.state.client_address2}
                          placeholder="Enter Appartment, Suite etc"
                          change={this.handleChange}
                          maxLength={50}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <TextField
                          label="State"
                          type="text"
                          error={this.state.errorClientState}
                          id="client_state"
                          name="client_state"
                          value={this.state.client_state}
                          placeholder="Enter state"
                          change={this.handleChange}
                          maxLength={3}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <TextField
                          label="Zip Code"
                          type="text"
                          error={this.state.errorClientZip}
                          id="client_zip"
                          name="client_zip"
                          value={this.state.client_zip}
                          placeholder="Enter zip code"
                          change={this.handleChange}
                          maxLength={9}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label
                          className="text-dark"
                          style={{
                            marginBottom: "0.5rem",
                            fontWeight: "600",
                          }}
                        >
                          Country
                        </label>
                        <Select
                          id="client_country"
                          name="client_country"
                          className="customCountrySelect"
                          options={this.countryOptions}
                          value={{
                            label: this.state.client_country,
                            value: this.state.client_country,
                          }}
                          onChange={this.handleChangeCountry}
                          placeholder="Select country"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <TextField
                          label="Password"
                          type="password"
                          error={this.state.errorClientPass}
                          id="client_password"
                          name="client_password"
                          value={this.state.client_password}
                          placeholder="Enter password"
                          change={this.handleChange}
                          maxLength={30}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <TextField
                          label="Confirm Password"
                          type="password"
                          error={this.state.errorClientPass}
                          id="client_confirm_password"
                          name="client_confirm_password"
                          value={this.state.client_confirm_password}
                          placeholder="Enter confirm password"
                          change={this.handleChange}
                          maxLength={30}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 small_select">
                      <div className="form-group">
                        <label
                          className="text-dark"
                          style={{
                            marginBottom: "0.5rem",
                            fontWeight: "600",
                          }}
                        >
                          Account Status
                        </label>
                        <select
                          class="form-control form-control-lg"
                          id="client_account_status"
                          name="client_account_status"
                          value={this.state.client_account_status}
                          onChange={this.handleChange}
                        >
                          <option key="5" value="active">
                            Active
                          </option>
                          <option key="10" value="inactive">
                            Inactive
                          </option>
                        </select>
                      </div>
                    </div>
                    <div
                      className="custom-control custom-control-lg custom-checkbox col-lg-12"
                      style={{ padding: 0, marginLeft: "3rem" }}
                    >
                      <div className="form-group">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customCheck2"
                          onChange={() => {
                            const isChecked = !this.state.notifyCheckBox;
                            this.setState({
                              notifyCheckBox: isChecked,
                            });
                            console.log("Notify Checkbox Checked:", isChecked);
                          }}
                        />
                        <label class="custom-control-label" for="customCheck2">
                          Notify Client
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-4">&nbsp;</div>
                    <div style={{ clear: "both" }}></div>

                    <div className="col-lg-12 text-right">
                      <button
                        type="button"
                        className="btn btn-lg"
                        onClick={() => {
                          this.formUpdateClient();
                        }}
                        style={{
                          backgroundColor: THEME_COLOR,
                          color: THEME_TEXT_COLOR,
                          float: "right",
                        }}
                        disabled={this.state.disabled}
                      >
                        Update Profile
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Client Update Modal End */}
          {/* New Client Modal Start */}
          <div className="modal fade" tabindex="-1" id="modalAddNewClient">
            <div
              className="modal-dialog modal-dialog-top modal-xl"
              role="document"
            >
              <div className="modal-content">
                <a
                  href="#"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-header">
                  <h5 className="modal-title">
                    <em
                      className="icon ni ni-users"
                      style={{ marginRight: "15px" }}
                    ></em>{" "}
                    Add New Client
                  </h5>
                </div>
                <div className="modal-body">
                  {this.state.formLoader === true ? (
                    FormLoader()
                  ) : (
                    <div className="row g-4">
                      {this.state.clientErrorMessage !== "" ? (
                        <div className="col-md-12 mb-3">
                          <div
                            className="example-alert example-alert-revoke"
                            style={{ marginBottom: "15px" }}
                          >
                            <div className="alert alert-pro alert-danger">
                              <div className="alert-text">
                                <h4>Error</h4>
                                <p>{this.state.clientErrorMessage}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.clientSuccessMessage !== "" ? (
                        <div className="col-md-12 mb-3">
                          <div
                            className="example-alert example-alert-revoke"
                            style={{ marginBottom: "15px" }}
                          >
                            <div className="alert alert-pro alert-success">
                              <div className="alert-text">
                                <h4>Success</h4>
                                <p>{this.state.clientSuccessMessage}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="form-control-group">
                            <MutextField
                              required
                              id="client_name"
                              name="client_name"
                              type="text"
                              label="Full Name"
                              defaultValue={this.state.client_name}
                              inputProps={{ maxLength: 64 }}
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[^a-zA-Z0-9 ]/gi,
                                  ""
                                );
                              }}
                              helperText="Full name of the invite recipient."
                              onChange={this.handleChange}
                              variant="outlined"
                              fullWidth
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="form-control-group">
                            <MutextField
                              required
                              id="client_email"
                              name="client_email"
                              type="text"
                              label="Email Address"
                              defaultValue={this.state.client_email}
                              helperText="Email address of the invite recipient."
                              inputProps={{ maxLength: 100 }}
                              onChange={this.handleChange}
                              variant="outlined"
                              fullWidth
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <div className="form-control-group">
                            <TextareaAutosize
                              maxrows={4}
                              name="invitation_comment"
                              id="invitation_comment"
                              aria-label=""
                              placeholder="Add Comment *"
                              inputProps={{ maxLength: 500 }}
                              //maxLength={500}
                              value={this.state.invitation_comment}
                              helperText="Maximum 500 characters"
                              onChange={this.handleChange}
                              style={{
                                width: "100%",
                                height: "100px",
                                borderColor: "rgba(0, 0, 0, 0.125)",
                                borderRadius: "4px",
                                padding: "5px"
                              }}
                              variant="outlined"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 d-flex justify-content-end">
                        <button
                          type="button"
                          onClick={() => this.saveNewClient()}
                          className="btn btn-primary"
                          disabled={this.state.disabled}
                        >
                          <span>Send Invite</span>{" "}
                          <em className="icon ni ni-emails"></em>
                        </button>
                      </div>
                      <div className="col-md-12">
                        <div className="example-alert">
                          <div className="alert alert-light">
                            <ul className="list">
                              <li>
                                An email will be dispatched to the provided
                                email address.
                              </li>
                              <li>
                                This email will contain a secure, tokenized
                                link for client registration.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* New Client Modal End */}
          {/* Modal Invitation Comment */}
          <div class="modal fade" tabindex="-1" id="modalInvitationComment">
            <div class="modal-dialog modal-xl modal-dialog-top" role="document">
              <div class="modal-content">
                <a href="#" class="close" data-dismiss="modal" aria-label="Close">
                  <em class="icon ni ni-cross"></em>
                </a>
                <div class="modal-header">
                  <h5 class="modal-title">Invitation Comments</h5>
                </div>
                <div class="modal-body">
                  <p className="text-dark">{this.state.invitation_comment && this.state.invitation_comment !== "" ? this.state.invitation_comment : "No Comments"}</p>
                </div>
              </div>
            </div>
          </div>
          {/* Modal Invitation Comment */}
          {/* MODAL Delete Client START */}
          <div
            className="modal fade"
            tabIndex="-1"
            id="modalDeleteAlert"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <a
                  style={{ cursor: "pointer" }}
                  className="close"
                  onClick={() => {
                    this.modalHideDel();
                  }}
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-trash bg-success"></em>
                    <h4 className="nk-modal-title">
                      Delete Invited Client?
                    </h4>
                    {this.state.clientInviteErrorMessage !== "" ? (
                      <div
                        className="example-alert"
                        style={{ marginBottom: "15px" }}
                      >
                        <div className="alert alert-pro alert-danger">
                          <div className="alert-text">
                            <h4>Error</h4>
                            <p>{this.state.clientInviteErrorMessage}</p>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.state.clientInviteSuccessMessage !== "" ? (
                      <div
                        className="example-alert"
                        style={{ marginBottom: "15px" }}
                      >
                        <div className="alert alert-pro alert-success">
                          <div className="alert-text">
                            <h4>Success</h4>
                            <p>
                              {this.state.clientInviteSuccessMessage}
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="nk-modal-text">
                      <div className="caption-text">
                        Are you sure you want to delete this Invited
                        Client?
                      </div>
                    </div>
                    <div className="nk-modal-action">
                      <a
                        style={{ cursor: "pointer", color: "white" }}
                        className="btn btn-lg btn-mw btn-primary"
                        onClick={() => {
                          this.modalHideDel();
                        }}
                      >
                        CANCEL
                      </a>
                      {"  "}
                      <button
                        style={{ cursor: "pointer" }}
                        className="btn btn-lg btn-mw btn-danger"
                        onClick={() => {
                          this.deleteInviteClient();
                        }}
                        disabled={this.state.disabled}
                      >
                        DELETE
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* MODAL Delete Client End */}
          {/* Resend Invitation Modal Start */}
          <div
            className="modal fade"
            tabIndex="-1"
            id="modalresendInvitation"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                {this.state.clientInviteSuccessMessage && (
                  <>
                    <a
                      style={{ cursor: "pointer" }}
                      className="close"
                      onClick={() => {
                        this.modalHideResend();
                      }}
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-body modal-body-lg text-center">
                      <div className="nk-modal">
                        <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
                        <h4
                          className="nk-modal-title"
                          style={{ fontSize: "1.3rem" }}
                        >
                          {this.state.clientInviteSuccessMessage}
                        </h4>
                      </div>
                    </div>
                  </>
                )}
                {this.state.clientInviteErrorMessage && (
                  <>
                    <a
                      style={{ cursor: "pointer" }}
                      className="close"
                      onClick={() => {
                        this.modalHideResend();
                      }}
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-body modal-body-lg text-center">
                      <div className="nk-modal">
                        <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-cross bg-danger"></em>
                        <h4
                          className="nk-modal-title"
                          style={{ fontSize: "1.3rem" }}
                        >
                          {this.state.clientInviteErrorMessage}
                        </h4>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          {/* Resend Invitation Modal Ends */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Clients);
